import { Control, UseFormWatch } from 'react-hook-form'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import CertificatesTemplateIcons, {
  ICertificatesTemplateIcons,
} from '../../CertificatesTemplateIcons'
import './TaskbarSelect.scss'
import { DefaultOptionType } from 'antd/es/select'
type Props = {
  name: string
  control: Control<any>
  icon: ICertificatesTemplateIcons
  watch: UseFormWatch<any>
  options: DefaultOptionType[]
}

const TaskbarSelect = ({ name, control, icon, watch, options }: Props) => {
  return (
    <div className='sapp-certificate-taskbar_select'>
      <HookFormSelectAntd name={name} control={control} options={options}></HookFormSelectAntd>
      <CertificatesTemplateIcons icon={icon} isOpacity={!watch(name)}></CertificatesTemplateIcons>
    </div>
  )
}

export default TaskbarSelect
