import { useQuery } from 'react-query'
import { MajorApi } from 'src/apis/major'
import { majorKeys } from 'src/constants/queryKeyFactory'

const useMajors = () => {
  const {
    data: majors,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: majorKeys.all,
    queryFn: async () => await MajorApi.getMajors(),
  })

  if (isLoading) {
    return {
      majors: [],
      isFetchingUniversity: true,
      isUniversitySuccess: false,
    }
  }

  if (isError) {
    return {
      majors: [],
      isFetchingUniversity: false,
      isUniversitySuccess: false,
    }
  }

  return {
    majors: majors?.data || [],
    isFetchingMajors: isLoading || isFetching,
    isMajorsSuccess: isSuccess,
  }
}

export default useMajors
