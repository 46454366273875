import { Select } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { KTIcon } from 'src/_metronic/helpers'
import { ClassesApi } from 'src/apis/classes'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import SappModal from 'src/components/base/SappModal'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import ListFilterLayout from 'src/components/layout/listFilter'
import { monthsOfYear, PROGRAM } from 'src/constants'
import { FILTER_SELECT_SORTBY_EXAM } from 'src/constants/classes'
import { BUTTON_TEXT, DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { cleanParamsAPI, convertDateStringDayMonthYear } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import TableExamCMA from './tableExamCMA'
import TableExamACCA from './tableExamsACCA'
import TableExamCFA from './tableExamsCFA'

const { Option } = Select

interface IProps {
  open: boolean
  setOpen: any
  id?: any
  course_category_id?: string
  course_id: string
  course_category_type?: string
  checkedList: any
  setCheckedList: React.Dispatch<React.SetStateAction<any>>
}
const fieldNames = [
  'sort_type',
  'registration_opening_date',
  'early_registration_deadline',
  'standard_registration_deadline',
  'registration_closing_date',
  'exam_month',
  'exam_year',
]
const initialValues: any = {
  sort_type: '',
  registration_opening_date: '',
  early_registration_deadline: '',
  standard_registration_deadline: '',
  registration_closing_date: '',
  exam_month: '',
  exam_year: '',
}
const AddExam = ({
  open,
  setOpen,
  id,
  course_id,
  course_category_type,
  checkedList,
  setCheckedList,
}: IProps) => {
  const [searchParams, setSearchParams] = useState<any>(initialValues)

  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState(false)
  const getParams = (
    sort_type: string,
    registration_opening_date?: string,
    early_registration_deadline?: string,
    standard_registration_deadline?: string,
    registration_closing_date?: string,
    exam_month?: string,
    exam_year?: string
  ) => ({
    sort_type,
    registration_opening_date: registration_opening_date,
    early_registration_deadline: early_registration_deadline,
    standard_registration_deadline: standard_registration_deadline,
    registration_closing_date: registration_closing_date,
    exam_month,
    exam_year,
  })
  const queryParams = {
    sort_type: '',
    registration_opening_date: '',
    early_registration_deadline: '',
    standard_registration_deadline: '',
    registration_closing_date: '',
    exam_month: '',
    exam_year: '',
    page_index: 1,
    page_size: 10,
  }
  const [examList, setExamList] = useState<any>()
  const [selectExam, setSelectExam] = useState<any>([])

  useEffect(() => {
    if (checkedList) {
      setSelectExam(checkedList)
    }
  }, [checkedList, open])

  const handleSubmit = () => {
    setOpen(false)
    setCheckedList(selectExam)
    reset()
  }

  const toggleCheck = (data: any) => {
    setSelectExam([data])
  }

  const { control, getValues, reset, setValue } = useForm<any>()

  const fetchExamList = async (currentPage: number, pageSize: number) => {
    setLoading(true)
    try {
      // TODO: check xem có tồn tại params ở trên router không?

      const cleanedParams = cleanParamsAPI(
        getParams(
          searchParams?.sort_type,
          searchParams?.registration_opening_date,
          searchParams?.early_registration_deadline,
          searchParams?.standard_registration_deadline,
          searchParams?.registration_closing_date,
          searchParams?.exam_month,
          searchParams?.exam_year
        )
      )
      getListExam(currentPage, pageSize, cleanedParams)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
  const getListExam = async (currentPage: number, pageSize: number, params?: any) => {
    const res = await ClassesApi.getListExamBySubject({
      course_id: course_id || id,
      page_index: currentPage || 1,
      page_size: pageSize || 10,
      params: params,
    })
    setExamList(res?.data?.data || [])
  }

  const convertDateRange = (value: any, type: 'string' | 'iso' = 'string') => {
    const convertRange =
      value !== '' && value?.fromDate !== null && value?.toDate !== null
        ? `${convertDateStringDayMonthYear(value?.fromDate, type)};${convertDateStringDayMonthYear(
            value?.toDate,
            type
          )}`
        : ''
    return convertRange
  }

  const onSubmit = () => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        replaceValueAll(getValues('sort_type')),
        convertDateRange(getValues('registration_opening_date'), 'iso') ?? '',
        convertDateRange(getValues('early_registration_deadline'), 'iso') ?? '',
        convertDateRange(getValues('standard_registration_deadline'), 'iso') ?? '',
        convertDateRange(getValues('registration_closing_date'), 'iso') ?? '',
        getValues('exam_month') ?? '',
        getValues('exam_year') ? dayjs(getValues('exam_year')).format('YYYY') : ''
      )
    )
    setSearchParams(cleanedParams)
    getListExam(1, 10, cleanedParams)
  }

  const handleResetFilter = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setSearchParams(initialValues)
    getListExam(1, 10, {})
  }
  useEffect(() => {
    if (id !== undefined && open) {
      handleResetFilter()
    }
  }, [id, open])

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchExamList(page_index, page_size)
  }

  const type = useMemo(() => {
    return course_category_type
  }, [course_category_type])

  const handleClose = () => {
    let body = [DESCRIPTION_POPUPCONFIRM]
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body,
      onClick: () => {
        setOpen(false)
        reset()
      },
    })
  }

  return (
    <SappModal
      title=''
      hideHeader
      open={open}
      handleClose={handleClose}
      dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
      classNameBody={'sapp-m-h-unset'}
      showFooter={false}
    >
      {contextHolder}
      <div className='d-flex justify-content-between px-0 align-items-center'>
        <div className='sapp-title-modal-create-class'>{`Add ${
          type?.toUpperCase() || 'ACCA'
        } Exam`}</div>
        <div className='d-flex align-items-center'>
          {selectExam.length > 0 && (
            <div className='sapp-selected-count fs-6 me-5'>{`${selectExam.length} Selected`}</div>
          )}
          <SAPPDialogButtonsCancelSubmit
            type='button'
            cancelClick={handleClose}
            cancelButtonCaption='Cancel'
            okButtonCaption='Add'
            okOnClick={handleSubmit}
            className='justify-content-between d-flex flex-row-reverse'
            classNameCancel='fw-bold me-0'
            classNameSubmit='fw-bold me-5'
            isListScreen
            // loading={loading}
          />
        </div>
      </div>
      <div className='px-0'>
        <div className='card-header border-0 pt-6 px-0'>
          <ListFilterLayout>
            <HookFormSelectAntd
              placeholder='Tháng thi'
              name={'exam_month'}
              control={control}
              allowClear
              suffixIcon={<KTIcon iconName='calendar' className='fs-4' />}
              defaultValue={queryParams?.exam_month}
              options={monthsOfYear}
            />
            <HookFormDateTime
              control={control}
              name={'exam_year'}
              placeholder='Năm thi'
              format={'YYYY'}
              picker='year'
            />
            {type === PROGRAM.CFA && [
              <RangeDateTimePicker
                control={control}
                name='registration_opening_date'
                allowClear={true}
                placeholder={['Mở đăng ký', 'To']}
                defaultValue={queryParams?.registration_opening_date}
              />,
              <RangeDateTimePicker
                control={control}
                name='early_registration_deadline'
                allowClear={true}
                placeholder={['Đăng ký sớm', 'To']}
                defaultValue={queryParams?.early_registration_deadline}
              />,

              <RangeDateTimePicker
                control={control}
                name='standard_registration_deadline'
                allowClear={true}
                placeholder={['Đăng ký chuẩn', 'To']}
                defaultValue={queryParams?.standard_registration_deadline}
              />,
            ]}
            {type === PROGRAM.CMA && [
              <RangeDateTimePicker
                control={control}
                name='registration_closing_date'
                allowClear={true}
                placeholder={['Ngày đăng ký cuối', 'To']}
                className='sapp-width-picker-range'
                defaultValue={queryParams?.registration_closing_date}
              />,
            ]}
            <HookFormSelectAntd
              name='sort_type'
              placeholder='Sort by'
              control={control}
              defaultValue={queryParams?.sort_type}
              className='sapp-fs-select fs-6'
              options={FILTER_SELECT_SORTBY_EXAM}
            />
          </ListFilterLayout>
        </div>
        <div className='card-header border-0 pt-6 px-0'>
          <div className='container m-0'>
            <div className='row'>
              <div className='col-sm-4 col-lg-8 col-xl-4 px-0'>
                <SAPPFIlterButton
                  titleReset='Reset'
                  okClick={onSubmit}
                  resetClick={handleResetFilter}
                  titleSubmit={BUTTON_TEXT.SEARCH}
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
        {type === 'CFA' ? (
          <TableExamCFA
            checkedList={selectExam}
            examList={examList}
            handlePaginationChange={handlePaginationChange}
            loading={loading}
            toggleCheck={toggleCheck}
          />
        ) : type === 'ACCA' ? (
          <TableExamACCA
            checkedList={selectExam}
            examList={examList}
            handlePaginationChange={handlePaginationChange}
            loading={loading}
            toggleCheck={toggleCheck}
          />
        ) : (
          <TableExamCMA
            checkedList={selectExam}
            examList={examList}
            handlePaginationChange={handlePaginationChange}
            loading={loading}
            toggleCheck={toggleCheck}
          />
        )}
      </div>
    </SappModal>
  )
}
export default AddExam
