import { Select } from 'antd'
import { capitalize } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonGroupFilter from 'src/components/base/button/ButtonGroupFilter'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import RoomTable from 'src/components/classroom/room/RoomTable'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import { CLASSROOM_TYPE, FACILITY_STATUS, PageLink } from 'src/constants'
import { TITLE_OPTIONS_ROOM } from 'src/constants/classroom'
import { LANG_SIDEBAR } from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import { ITabs } from 'src/type'
import { IFacilities, IRoomList } from 'src/type/area'
import { ROOM_MODE } from 'src/type/classroom'
import { cleanParamsAPI } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: TITLE_OPTIONS_ROOM.classroomlist,
  },
]
const fieldNames = ['search', 'province_code', 'fromDate', 'toDate']

const initialValues = {
  search: '',
  province_code: '',
  status: '',
}

const RoomClass = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const [roomList, setRoomList] = useState<IRoomList>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [areaList, setAreaList] = useState<IFacilities>({
    metadata: { total_pages: 0, total_records: 0, page_index: 0, page_size: 0 },
    facilities: [],
  })

  const searchParams = new URLSearchParams(search)

  const queryParams = {
    search: searchParams.get('search') ?? '',
    facility_id: searchParams.get('facility_id') ?? '',
    type: searchParams.get('type') ?? '',
    status: searchParams.get('status') ?? '',
    mode: searchParams.get('mode') ?? '',
    page_index: searchParams.get('page_index') ?? 1,
    page_size: searchParams.get('page_size') ?? 10,
  }

  const { control, getValues, reset, setValue, watch } = useForm({
    mode: 'onChange',
  })

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      search: getValues('search'),
      facility_id: replaceValueAll(getValues('facility_id')),
      type: replaceValueAll(getValues('type')) ?? '',
      mode: replaceValueAll(getValues('mode')) ?? '',
      status: replaceValueAll(getValues('status')),
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const filterParams = cleanParamsAPI({
    search: queryParams?.search?.trimStart()?.trimEnd() ?? '',
    facility_id: queryParams.facility_id ?? '',
    type: queryParams.type,
    status: queryParams.status ?? '',
    mode: queryParams.mode ?? '',
  })
  const getParams = (
    search?: string,
    facility_id?: string,
    type?: string,
    status?: string,
    mode?: string
  ) => ({
    search,
    facility_id,
    type,
    status,
    mode,
  })

  const { toggleCheckAll, isCheckedAll } = useChecked(roomList?.classRooms)

  const fetchRoomList = async (pageIndex: number = 1, pageSize: number = 10, params?: Object) => {
    try {
      setLoading(true)
      const res = await ClassroomApi.getRoomList({
        page_index: pageIndex,
        page_size: pageSize,
        params,
      })
      setRoomList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const fetchArea = async (page_index: number = 1, page_size: number = 10, params?: Object) => {
    try {
      const res = await ClassroomApi.getAreaClasses({ page_index, page_size, params })
      setAreaList((prev: IFacilities) => {
        return {
          metadata: res.data.metadata,
          facilities:
            res?.data?.metadata?.page_index === 1
              ? res?.data.facilities
              : prev?.facilities?.concat(res?.data?.facilities),
        }
      })
    } catch (error) {}
  }

  const onSubmit = () => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('search').trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('facility_id')),
        replaceValueAll(getValues('type')),
        replaceValueAll(getValues('status')),
        replaceValueAll(getValues('mode'))
      )
    )

    setLoading(true)
    delete cleanedParams.search
    const filterParam = { ...cleanedParams }
    if (getValues('search')?.trimStart()?.trimEnd()) {
      filterParam['search'] = `name=${getValues('search')},code=${getValues('search')}`
    }
    fetchRoomList(1, Number(queryParams.page_size) || 10, filterParam)
    handleChangeParams(1, Number(queryParams.page_size) || 10)
  }

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName as keyof typeof initialValues])
    })
    setLoading(true)
    navigate(PageLink.CLASSROOM_ROOM)
    fetchRoomList(1, 10)
  }

  const roomMode = useWatch({ control, name: 'mode' })

  useEffect(() => {
    const fromDate = watch('fromDate')
    const toDate = watch('toDate')

    if (fromDate && toDate && moment(toDate).isBefore(fromDate)) {
      setValue('toDate', moment(fromDate).endOf('day').toDate())
    }

    if (toDate && fromDate && moment(fromDate).isAfter(toDate)) {
      setValue('fromDate', moment(toDate).startOf('day').toDate())
    }
  }, [watch('fromDate'), watch('toDate')])

  const handleNextPage = async (
    totalPages?: number,
    pageIndex?: number,
    pageSize?: number,
    fetchData?: (page_index: number, page_size: number, params?: Object) => void,
    params?: Object
  ) => {
    if (totalPages && pageIndex && pageIndex < totalPages && fetchData && pageSize) {
      fetchData(pageIndex + 1, pageSize, params)
    }
  }
  return (
    <PageLayouts pageTitle={TITLE_OPTIONS_ROOM.classroomlist} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='px-9 border-0 pt-10 row'>
          <ListFilterLayout>
            <HookFormTextField
              control={control}
              name='search'
              placeholder='Search by name or code'
              defaultValue={queryParams?.search}
              onSubmit={onSubmit}
              isListScreen
            />
            <HookFormSelectAntd
              name='mode'
              placeholder='Mode'
              control={control}
              defaultValue={queryParams?.mode ?? ''}
              onChange={(value) => {
                if (value === ROOM_MODE.VIRTUAL) {
                  setValue('name', '')
                  setValue('facility_id', '')
                  setValue('type', '')
                }
              }}
              classNameHeight='sapp-h-40'
              options={Object.values(ROOM_MODE).map((mode) => ({
                label: capitalize(mode),
                value: mode,
              }))}
            />
            <HookFormSelectAntd
              name='facility_id'
              placeholder='Facility'
              control={control}
              defaultValue={queryParams?.facility_id ?? ''}
              classNameHeight='sapp-h-40'
              disabled={roomMode === ROOM_MODE.VIRTUAL}
              handleNextPage={() => {
                handleNextPage(
                  areaList?.metadata.total_pages,
                  areaList?.metadata.page_index,
                  areaList?.metadata.page_size,
                  fetchArea
                )
              }}
              onSearch={(text: string | undefined) => {
                fetchArea(1, 10, { search: `name=${text}` })
              }}
              onFocus={() => {
                fetchArea()
              }}
              showSearch
              options={areaList?.facilities?.map((area) => ({ label: area.name, value: area.id }))}
            />
            <HookFormSelectAntd
              name='type'
              placeholder='Type'
              control={control}
              defaultValue={queryParams?.type ?? ''}
              classNameHeight='sapp-h-40'
              options={CLASSROOM_TYPE}
              disabled={roomMode === ROOM_MODE.VIRTUAL}
            />
            <HookFormSelectAntd
              name='status'
              placeholder='Status'
              control={control}
              defaultValue={queryParams?.status ?? ''}
              classNameHeight='sapp-h-40'
              options={FACILITY_STATUS}
            />
          </ListFilterLayout>
        </div>
        <ButtonGroupFilter
          onSubmit={onSubmit}
          onReset={handleResetFilter}
          loading={loading}
          onCreate={() => navigate(PageLink.CLASSROOM_ROOM_CREATE)}
          createTitle='Create Room'
        />
        <RoomTable
          roomList={roomList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchRoomList={fetchRoomList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          isCheckedAll={isCheckedAll}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(RoomClass)
