import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import SappDrawer from 'src/components/base/SappDrawer'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { VALIDATE_MAX_NAME, VALIDATE_MIN_NAME, VALIDATION_FIELD } from 'src/constants'
import { getSelectOptions } from 'src/helper/getSelectOptions'
import { useConfirm } from 'src/hooks/use-confirm'
import useInfinitePrograms from 'src/hooks/useInfiniteCourseCategory'
import { ISubjectDetail } from 'src/type/courses'
import { z } from 'zod'

interface IProps {
  open: boolean
  handleClose: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
  id: string
  fetchSubject: (pageIndex: number, pageSize: number, params?: Object) => void
  currentPage: number
  pageSize: number
  loading: boolean
  disabled?: boolean
  filterParamsPagination: Object
}

interface IInputProps {
  name: string
  code?: string
  course_category_id: string
}

const CreateAndUpdateSubject = ({
  handleClose,
  open,
  setLoading,
  id,
  fetchSubject,
  currentPage,
  pageSize,
  loading,
  disabled,
  filterParamsPagination,
}: IProps) => {
  const [subject, setSubject] = useState<ISubjectDetail>()
  const { confirm, contextHolder } = useConfirm()

  // Validate for input
  const validationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATE_MIN_NAME })
      .max(100, { message: VALIDATE_MAX_NAME }),
    code: z.string().optional(),
    course_category_id: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATE_MIN_NAME })
      .max(100, { message: VALIDATE_MAX_NAME }),
  })

  // Using validate for input
  const { control, handleSubmit, reset, setValue, getValues } = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      code: '',
      course_category_id: '',
    },
  })

  const handleResetForm = () => {
    handleClose()
    reset()
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => {
        handleResetForm()
      },
    })
  }

  const onSubmit = async (data: IInputProps) => {
    setLoading(true)
    try {
      if (id) {
        const res = await CoursesAPI.ediSubject(id, data)
        if (res.success) {
          fetchSubject(currentPage || 1, pageSize || 10, filterParamsPagination)
        }
        toast.success('Update Successfully!')
        handleResetForm()
      } else {
        const res = await CoursesAPI.addSubject({ data })
        if (res.success) {
          fetchSubject(currentPage || 1, pageSize || 10, filterParamsPagination)
        }
        toast.success('Create Successfully!')
        handleResetForm()
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const fetchCategoryDetail = async () => {
    try {
      const res = await CoursesAPI.getSubject(id)
      setSubject(res.data)
    } catch (error) {}
  }

  useEffect(() => {
    if (!isEmpty(id)) {
      fetchCategoryDetail()
    }
  }, [id, open])

  const {
    programs,
    refetch: refetchPrograms,
    hasNextPage: hasNextPageProgram,
    fetchNextPage: fetchNextPageProgram,
    debounceSearch,
  } = useInfinitePrograms()

  useEffect(() => {
    if (open && id) {
      setValue('name', subject?.name ?? '')
      setValue('code', subject?.code ?? '')
      setValue('course_category_id', subject?.course_category_id ?? '')
    }
  }, [setValue, id, subject?.name, subject?.course_category_id, open])

  return (
    <SappDrawer
      rootClassName='sapp-question_essay-drawer'
      open={open}
      title={id ? 'Edit Subject' : 'Create Subject'}
      cancelButtonCaption={'Cancel'}
      okButtonCaption={'Save'}
      handleSubmit={handleSubmit(onSubmit)}
      handleClose={handleCancel}
      disabled={disabled}
      width='50%'
      loading={loading}
    >
      {contextHolder}
      <div className='mb-8 fv-row'>
        <HookFormTextField control={control} name='name' label='Name' required placeholder='Name' />
      </div>
      <div className='mb-8 fv-row'>
        <HookFormTextField control={control} name='code' label='Code' placeholder='Code' />
      </div>
      <div className='mb-8 fv-row'>
        <HookFormSelectAntd
          control={control}
          name='course_category_id'
          label='Program'
          placeholder='Program'
          onSearch={(text) => {
            debounceSearch(text)
          }}
          defaultValue={getValues('course_category_id')}
          showSearch
          handleNextPage={hasNextPageProgram && fetchNextPageProgram}
          onDropdownVisibleChange={(open) => {
            if (open && !programs) {
              refetchPrograms()
              return
            }
          }}
          options={getSelectOptions(
            programs?.map((program) => ({ label: program.name, value: program.id })) ?? []
          )}
        />
      </div>
    </SappDrawer>
  )
}

export default CreateAndUpdateSubject
