import { useParams } from 'react-router-dom'
import ExamCreate from 'src/components/exam/ExamCreate'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink, TITLE } from 'src/constants'
import { ExamRouteParams, ITabs } from 'src/type'

const ExamCreatePage = () => {
  const { program } = useParams<ExamRouteParams>()
  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.DASHBOARD}`,
      title: 'LMS',
    },
    {
      link: `${PageLink.EXAMS}/${program}`,
      title: TITLE.EXAM_LIST,
    },
    {
      link: '',
      title: `Create`,
    },
  ]

  return (
    <PageLayouts pageTitle={`Create Exam ${program?.toUpperCase()}`} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <ExamCreate />
      </div>
    </PageLayouts>
  )
}

export default ExamCreatePage
