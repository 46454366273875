import { zodResolver } from '@hookform/resolvers/zod'
import { uniqueId } from 'lodash'
import { Col, Row } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import { KTIcon } from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { VALIDATION_FIELD } from 'src/constants'
import { getUppercaseByNumber } from 'src/utils'
import { z } from 'zod'

import { Select } from 'antd'
import { useEffect, useState } from 'react'
import ErrorMessage from 'src/common/ErrorMessage'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { ICommonQuestionProps } from 'src/type/question-bank'
import { defaultQuestionValues, validationQuestionSchema } from '../shared/consts'
import {
  ANSWER_CORRECT_TYPE,
  IInputQuestionProps,
  IQuestion,
  QUESTION_TYPES,
} from '../shared/interfaces'
import { inValidAnswers, validateAnswers } from '../shared/utils'
import LayoutQuestion from './LayoutQuestion'

interface IAnswer {
  answer: string
  is_correct: ANSWER_CORRECT_TYPE
  answerId: string
}
interface IInputProps extends IInputQuestionProps {
  answers?: IAnswer[]
}
const defaultValues = {
  ...defaultQuestionValues,
  answers: [
    { answer: '', answerId: uniqueId('answer_'), is_correct: ANSWER_CORRECT_TYPE.F },
    { answer: '', answerId: uniqueId('answer_'), is_correct: ANSWER_CORRECT_TYPE.F },
  ],
}

const OneChoiceQuestion = ({ open, onClose, type, id, actionType, data }: ICommonQuestionProps) => {
  const validationSchema = z
    .object({
      ...validationQuestionSchema,
      answers: z.array(
        z.object({
          answer: z
            .string({ required_error: VALIDATION_FIELD })
            .trim()
            .min(1, VALIDATION_FIELD)
            .max(1000),
          answerId: z.string(),
          is_correct: z.string().optional(),
        })
      ),
    })
    .superRefine((arg: any & { answers: IAnswer }, ctx) => {
      if (arg.is_self_reflection !== 'true') {
        const isInValidAnswers = inValidAnswers<IAnswer>(arg.answers, 'ONE_TRUE')
        if (isInValidAnswers) {
          ctx.addIssue(isInValidAnswers)
        }
      }
    })

  const useFormProp = useForm<IInputProps & { customAnswersError: string }>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })
  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
  } = useFormProp

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answers',
  })

  // Kiểm tra nếu là câu hỏi phản chiếu thì đặt default cho các đáp án là True
  const answers = watch('answers')
  const isSelfReflection = watch('is_self_reflection')
  useEffect(() => {
    const isCorrect = isSelfReflection === 'true' ? ANSWER_CORRECT_TYPE.T : ANSWER_CORRECT_TYPE.F
    if (isCorrect && answers?.length) {
      answers.map((_, index) => {
        setValue(`answers.${index}.is_correct`, isCorrect)
      })
    }
  }, [isSelfReflection])

  const buildRequest = (data: IInputProps): { partialQuestion: Partial<IQuestion> } => {
    return {
      partialQuestion: {
        qType: QUESTION_TYPES.ONE_CHOICE,
        answers: data.answers?.map((e, i) => ({
          is_correct: e.is_correct === ANSWER_CORRECT_TYPE.T ? true : false,
          answer: (e.answer ?? '').trim(),
          answer_position: i + 1,
        })),
      },
    }
  }
  const [isBlocked, setIsBlocked] = useState(false)

  const fetchData = (data: IQuestion): Partial<IInputProps> => {
    if (!data) {
      return {}
    }
    if (data?.status === 'BLOCK') {
      setIsBlocked(true)
    }
    return {
      answers: data.answers?.map((e) => ({
        is_correct: e.is_correct ? ANSWER_CORRECT_TYPE.T : ANSWER_CORRECT_TYPE.F,
        answer: e.answer,
        answerId: uniqueId('answer_'),
      })),
    }
  }

  return (
    <LayoutQuestion
      defaultValues={defaultValues}
      actionType={actionType}
      data={data}
      id={id}
      fetchData={fetchData}
      open={open}
      onClose={onClose}
      useForm={useFormProp}
      type={type}
      buildRequest={buildRequest}
    >
      {/* start:: answer */}
      <div className='mb-8'>
        <Row>
          <Col md={9}>
            <SappLabel label='Danh Sách Đáp Án' required />
          </Col>
        </Row>
        <div>
          {answers?.map((field, index) => (
            <div key={field.answerId}>
              <Row>
                <Col md={9} className='mb-5'>
                  <div className='d-flex align-items-center'>
                    <div className='w-100'>
                      <HookFormTextField
                        groupText={getUppercaseByNumber(index + 1)}
                        disabled={isBlocked}
                        control={control}
                        name={`answers.${index}.answer` as const}
                        placeholder={'Đáp án ' + (index + 1)}
                        // guideline={[
                        //   'Cho phép nhập chữ hoa, thường, chữ số và ký tự đặc biệt, không giới hạn ký tự.',
                        // ]}
                      ></HookFormTextField>
                    </div>
                  </div>
                </Col>
                <Col md={3} className='mb-5'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='sapp-flex-1'>
                      <HookFormSelectAntd
                        classNameHeight='sapp-h-45px'
                        name={`answers.${index}.is_correct` as const}
                        control={control}
                        disabled={isBlocked || isSelfReflection === 'true'}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        customError={!!errors?.customAnswersError}
                        className='fs-6'
                        onChange={() => {
                          if (isSelfReflection !== 'true') {
                            validateAnswers({
                              setError,
                              clearErrors,
                              answers: getValues('answers'),
                              path: 'customAnswersError',
                              checkEvery: 'ONE_TRUE',
                            })
                          }
                        }}
                        options={[
                          { label: 'Đúng', value: ANSWER_CORRECT_TYPE.T },
                          { label: 'Sai', value: ANSWER_CORRECT_TYPE.F },
                        ]}
                      />
                    </div>
                    {fields.length > 2 && (
                      <div className='ms-4'>
                        <ButtonIconOnly
                          iconName={'trash'}
                          activeColor='danger'
                          disabled={isBlocked}
                          onClick={() => {
                            setTimeout(() => {
                              remove(index)
                            })
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {index === fields.length - 1 && (
                    <ErrorMessage>{errors?.['customAnswersError']?.message}</ErrorMessage>
                  )}
                </Col>
              </Row>
            </div>
          ))}
          <Row>
            <Col md={9}>
              <ButtonIcon
                title={'Thêm đáp án'}
                disabled={isBlocked}
                className='h-45px w-100 d-flex justify-content-start align-items-center mt-xl-0 mt-4'
                customButton='btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 text-gray-500 p-0'
                type='button'
                onClick={() => {
                  setTimeout(() => {
                    append({
                      answer: '',
                      answerId: uniqueId('answer_'),
                      is_correct:
                        isSelfReflection === 'true' ? ANSWER_CORRECT_TYPE.T : ANSWER_CORRECT_TYPE.F,
                    })
                  })
                }}
              >
                <KTIcon iconName='plus' className='fs-2 text-gray-500 p-3' />
              </ButtonIcon>
            </Col>
            <Col></Col>
          </Row>
        </div>
      </div>
      {/* end:: answer*/}
    </LayoutQuestion>
  )
}
export default OneChoiceQuestion
