import { useNavigate, useParams } from 'react-router-dom'
import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { FORMAT_DATETIME, HEADER_ENTRANCE_TEST_LIST, PARTICIPANT_ENUM } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
  handleParticipantLevel,
} from 'src/utils'
import { Link } from 'react-router-dom'
import { IParticipantInTest, IParticipantInTestList } from 'src/type/entrancetest'
import ActionCell from 'src/components/base/action/ActionCell'
import { EntranceApi } from 'src/apis/entrance-test'
import toast from 'react-hot-toast'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { format } from 'date-fns'
import { ICommonProp } from 'src/type/common'

interface IQueryParams {
  text: string
  status?: string
  level: string
  source: string
  sortType: string
  fromDate: Date
  toDate: Date

  page_index: number
  page_size: number
}

export type IParams = (
  text: string,
  status: string,
  level: string,
  source: string,
  sortType: string,
  fromDate?: string | Date,
  toDate?: string | Date,
  dateField?: string
) => {
  text: string
  status: string
  level: string
  source: string
  sortType: string
  fromDate?: string | Date
  toDate?: string | Date
  dateField?: string
}

interface IProps extends ICommonProp {
  participantList?: IParticipantInTestList
  fetchParticipantList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  getParams: IParams
  queryParams: IQueryParams
}

// define headers
const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Name',
    className: 'min-w-175px pe-5',
  },
  {
    label: 'Email',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Phone',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Result',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Level',
    className: 'min-w-50px pe-5',
  },
  {
    label: 'Submission Time',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Source',
    className: 'max-w-100px pe-5',
  },
]

const ParticipantTable = ({
  participantList,
  loading,
  setLoading,
  fetchParticipantList,
  getParams,
  queryParams,
  isCheckedAll,
  toggleCheckAll,
}: IProps) => {
  const { id } = useParams()
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)
  const showQueryParams =
    queryParams.text ||
    queryParams.status ||
    queryParams.level ||
    queryParams.source ||
    queryParams.sortType ||
    queryParams.fromDate ||
    queryParams.toDate

  // TODO: dùng để filter params trên router
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.text.trim() ?? '',
      queryParams.status ?? '',
      queryParams.level ?? '',
      queryParams.source ?? '',
      queryParams.sortType ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      showQueryParams ? 'updated_at' : 'created_at'
    )
  )

  /**
   * Handle update params in url
   */
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      text: queryParams.text ?? '',
      status: queryParams.status ?? '',
      level: queryParams.level ?? '',
      source: queryParams.source ?? '',
      sortType: queryParams.sortType ?? '',
      fromDate: formatDate(queryParams.fromDate as Date) ?? '',
      toDate: formatDate(queryParams.toDate as Date) ?? '',
      page_index: currenPage,
      page_size: size,
      dateField: 'updated_at',
    }
    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  /**
   * Handle pagination
   */
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchParticipantList(page_index, page_size, filterParamsPagination)
    handleChangeParams(page_index || 1, page_size || 10)
  }

  /**
   * Handle delete participant
   */
  const handleDelete = async (participant: IParticipantInTest) => {
    if (!id) return
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: async () => {
        try {
          if (participant?.quiz_attempts?.length && !participant?.is_sapp_operator) {
            toast.error('Student Finished The Test!')
            return
          }
          setLoading(true)
          await EntranceApi.deleteParticipantFromEntranceTest(id, { user_ids: [participant.id] })
          fetchParticipantList(1, 10)
          toast.success('Delete The Participant Successfully!')
        } catch (error) {
        } finally {
          setLoading(false)
        }
      },
    })
  }

  return (
    <div className='card-body py-4'>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={participantList?.data}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        hasCheck={false}
      >
        {loading ? (
          <>
            {HEADER_ENTRANCE_TEST_LIST.map((header, i) => (
              <LoadingTable key={header} headers={HEADER_ENTRANCE_TEST_LIST} />
            ))}
          </>
        ) : (
          <>
            {participantList?.data?.map((participant: IParticipantInTest, index: number) => {
              const sourceType =
                participant.quiz_user_instances?.[0].source === PARTICIPANT_ENUM.FORM_REGISTER
                  ? PARTICIPANT_ENUM.FORM
                  : PARTICIPANT_ENUM.MANUALLY
              const submitTime = participant?.quiz_attempts?.[0]?.finished_at
                ? format(new Date(participant?.quiz_attempts?.[0]?.finished_at), FORMAT_DATETIME)
                : '--'
              const level = handleParticipantLevel(
                participant,
                participantList?.quiz?.course_category?.name
              )
              return (
                <tr key={participant.id}>
                  <td>
                    {index +
                      1 +
                      (participantList?.meta?.page_index - 1) * participantList?.meta?.page_size}
                  </td>
                  <td className='text-start'>
                    <Link
                      className='text-gray-800 sapp-text-truncate-1 text-hover-primary'
                      to={`/student/profile/${participant.id}`}
                    >
                      {participant?.detail?.full_name}
                    </Link>
                  </td>
                  <td className='text-start'>{participant?.user_contacts?.[0]?.email}</td>
                  <td className='text-start'>
                    <div className='sapp-text-truncate-1'>
                      {participant?.user_contacts?.[0]?.phone}
                    </div>
                  </td>
                  <td className='text-start'>
                    {participant?.quiz_attempts?.length ? 'Finished' : 'Unfinished'}
                  </td>
                  <td className='text-start'>
                    {participant?.quiz_attempts?.[0]?.ratio_score ? (
                      <Link
                        className='sapp-text-truncate-1 text-hover-primary text-gray-600'
                        to={`/entrance-test/list-entrance-test/result/${participant?.quiz_attempts?.[0]?.id}`}
                      >
                        {participant?.quiz_attempts?.[0]?.ratio_score}
                      </Link>
                    ) : (
                      '--'
                    )}
                  </td>
                  <td className='text-start'>{level}</td>
                  <td className='text-start'>{submitTime}</td>
                  <td className='text-start'>{sourceType}</td>
                  <td className='text-center sapp-absolute-column'>
                    {(participant?.is_sapp_operator || sourceType === PARTICIPANT_ENUM.FORM) && (
                      <ActionCell>
                        <div className='menu-item px-3' onClick={() => handleDelete(participant)}>
                          <div className='menu-link px-3'>Delete</div>
                        </div>
                      </ActionCell>
                    )}
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>

      <PagiantionSAPP
        currentPage={participantList?.meta?.page_index || 1}
        pageSize={participantList?.meta?.page_size || 10}
        totalItems={participantList?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default ParticipantTable
