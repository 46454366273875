import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { capitalize, isEmpty, isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import FieldNote from 'src/components/base/fieldNote/FieldNote'
import HeaderTab from 'src/components/base/HeaderTab'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import WarningText from 'src/components/base/WarningText'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import {
  CLASSROOM_TYPE,
  FACILITY_STATUS,
  FACILITY_STATUS_ENUM,
  PageLink,
  VALIDATION_EMPTY_FIELD,
  VALIDATION_FIELD,
} from 'src/constants'
import { ROOM_PROFILE, ROOM_PROFILE_URL, TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { IFacility, IFacilities, IRoom } from 'src/type/area'
import { ROOM_MODE } from 'src/type'
import { z } from 'zod'

const { Option } = Select

interface IProps {
  loading: boolean
  roomDetail: IRoom | undefined
  refetch: () => void
}

interface IInputProps {
  name: string
  code: string
  capacity: number
  deferred_student: number
  new_student: number
  type: string
  facility_id: string
  address?: string
  status: string
  mode: ROOM_MODE
  virtual_metadata: string
}

const RoomProfileSetting = ({ loading, roomDetail, refetch }: IProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const isCheckEnded = roomDetail?.status === 'ENDED' ? true : false
  const [isLoading, setIsLoading] = useState<boolean>()
  const [areaList, setAreaList] = useState<IFacilities>()
  const createValidationSchema = (selectedMode: ROOM_MODE) => {
    const baseSchema = {
      name: z
        .string({ required_error: VALIDATION_FIELD })
        .min(1, { message: VALIDATION_EMPTY_FIELD }),
      mode: z.string({ required_error: VALIDATION_FIELD }),
      status: z.string({ required_error: VALIDATION_FIELD }),
    }

    if (selectedMode === ROOM_MODE.PHYSICAL) {
      Object.assign(baseSchema, {
        capacity: z.number(),
        deferred_student: z.number(),
        new_student: z.number(),
        type: z
          .string({ required_error: VALIDATION_FIELD })
          .min(1, { message: VALIDATION_EMPTY_FIELD }),
        facility_id: z
          .string({ required_error: VALIDATION_FIELD })
          .min(1, { message: VALIDATION_EMPTY_FIELD }),
        address: z.string().optional(),
      })
    }

    if (selectedMode === ROOM_MODE.VIRTUAL) {
      Object.assign(baseSchema, {
        virtual_metadata: z.string({ required_error: VALIDATION_FIELD }),
      })
    }

    return z.object(baseSchema)
  }
  const fetchArea = async (page_index: number, page_size: number, params?: Object) => {
    try {
      let addedParams =
        roomDetail?.facility?.status === FACILITY_STATUS_ENUM.CLOSE
          ? {}
          : { status: FACILITY_STATUS_ENUM.ACTIVE }
      const res = await ClassroomApi.getAreaClasses({
        page_index,
        page_size,
        params: Object.assign(addedParams, params),
      })
      setAreaList((prev: IFacilities | undefined) => {
        return {
          metadata: res.data.metadata,
          facilities:
            res.data.metadata.page_index === 1
              ? res.data.facilities
              : [...(prev?.facilities ?? []), ...res.data.facilities]?.filter(
                  (item, index, self) => index === self.findIndex((t) => t.id === item.id)
                ),
        }
      })
    } catch (error) {}
  }

  const validationSchema = createValidationSchema(roomDetail?.mode as ROOM_MODE)
  const { handleSubmit, control, setError, clearErrors, setValue } = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const handleNextPage = async (
    totalPages?: number,
    pageIndex?: number,
    pageSize?: number,
    fetchData?: (page_index: number, page_size: number, params?: Object) => void,
    params?: Object
  ) => {
    if (totalPages && pageIndex && pageIndex < totalPages && fetchData && pageSize) {
      fetchData(pageIndex + 1, pageSize, params)
    }
  }

  const initData = async () => {
    if (!isEmpty(id) && !isUndefined(roomDetail)) {
      setValue('name', roomDetail?.name ?? '')
      setValue('code', roomDetail?.code ?? '')
      setValue('address', roomDetail?.address ?? '')
      setValue('facility_id', roomDetail?.facility_id ?? '')
      setValue('capacity', roomDetail?.capacity ?? 0)
      setValue('deferred_student', roomDetail?.deferred_student ?? 0)
      setValue('new_student', roomDetail?.new_student ?? 0)
      setValue('type', roomDetail?.type ?? '')
      setValue('status', roomDetail?.status ?? '')
      setValue('mode', roomDetail?.mode ?? ROOM_MODE.PHYSICAL)
      setValue('virtual_metadata', roomDetail?.virtual_metadata)
      setAreaList({
        metadata: { page_index: 1, page_size: 1, total_pages: 0, total_records: 0 },
        facilities: [roomDetail?.facility] as Array<any>,
      })
    }
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        refetch()
        initData()
        clearErrors()
        navigate(`${ROOM_PROFILE}/${id}/${ROOM_PROFILE_URL.OVERVIEW}`)
      },
    })
  }

  const onSubmit: SubmitHandler<IInputProps> = async (data: IInputProps) => {
    try {
      if (
        roomDetail?.mode === ROOM_MODE.PHYSICAL &&
        data.deferred_student + data.new_student !== data.capacity
      ) {
        setError('deferred_student', {
          message:
            'The total number of new students and deferred students must equal the capacity.',
        })
        setError('new_student', {
          message:
            'The total number of new students and deferred students must equal the capacity.',
        })
        setError('capacity', {
          message:
            'The total number of new students and deferred students must equal the capacity.',
        })
        return
      } else {
        clearErrors('deferred_student')
        clearErrors('new_student')
        clearErrors('capacity')
      }
      setIsLoading(true)
      if (!id) return
      await ClassroomApi.editRoom(id, data)
      refetch()
      navigate(`${ROOM_PROFILE}/${id}/${ROOM_PROFILE_URL.OVERVIEW}`)
      toast.success('Update Successfully!')
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    initData()
  }, [id, roomDetail])

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10'>
        <HeaderTab title={TITLE_OPTIONS_AREA.editFacility} />
        <div className='collapse show'>
          <div className='card-body card-body px-10 pt-8 pb-4 row'>
            <div className='mb-8 col-6'>
              <HookFormTextField
                required
                control={control}
                name='name'
                placeholder='Name'
                disabled={isCheckEnded}
                label='Classroom Name'
              />
            </div>
            <div className='mb-8 col-6'>
              <HookFormSelectAntd
                control={control}
                name='mode'
                placeholder='Mode'
                label='Mode'
                required
                allowClear={false}
                disabled
                options={Object.values(ROOM_MODE).map((mode) => ({
                  label: capitalize(mode),
                  value: mode,
                }))}
              />
            </div>
            {roomDetail?.mode === ROOM_MODE.PHYSICAL && (
              <>
                <div className='col-6 mb-8'>
                  <HookFormSelectAntd
                    control={control}
                    name='facility_id'
                    placeholder='Facility'
                    label='Facility'
                    required
                    disabled={!!id}
                    onChange={(value) => {
                      if (!value) {
                        fetchArea(1, 10)
                      }
                    }}
                    onSearch={(value) => {
                      if (value) {
                        fetchArea(1, 10, { search: `name=${value},code=${value}` })
                      } else {
                        fetchArea(1, 10)
                      }
                    }}
                    handleNextPage={handleNextPage(
                      areaList?.metadata.total_pages,
                      areaList?.metadata.page_index,
                      areaList?.metadata.page_size,
                      fetchArea
                    )}
                    showSearch
                    onFocus={() => fetchArea(1, 10)}
                    options={
                      areaList?.facilities?.map((area: IFacility) => ({
                        label: area.name,
                        value: area.id,
                      })) ?? []
                    }
                  />
                </div>
                <div className='col-6 mb-8'>
                  <HookFormTextField
                    required
                    control={control}
                    name='code'
                    placeholder='Code'
                    disabled={!!id}
                    label='Code'
                  />
                </div>
                <div className='col-12'>
                  <WarningText className='mb-8 mt-0' title='Địa chỉ phòng học'>
                    <ul>
                      <li className='sapp-content-alert mt-2'>
                        Người dùng chỉ cần nhập số tầng (Ví dụ: Tầng 5)
                      </li>
                    </ul>
                  </WarningText>
                </div>
                <div className='col-6 mb-8'>
                  <HookFormTextField
                    control={control}
                    name='address'
                    placeholder='Address'
                    label='Address'
                  />
                </div>
                <div className='col-6 mb-8'>
                  <HookFormSelectAntd
                    control={control}
                    name='type'
                    placeholder='Type'
                    label='Type'
                    required
                    showSearch
                    options={CLASSROOM_TYPE}
                  />
                </div>
                <div className='col-6 mb-8'>
                  <HookFormSelectAntd
                    control={control}
                    name='status'
                    placeholder='Status'
                    disabled={roomDetail?.facility?.status === FACILITY_STATUS_ENUM.CLOSE}
                    label='Status'
                    required
                    showSearch
                    options={FACILITY_STATUS}
                  />
                </div>
                <div className='col-6 mb-8'>
                  <HookFormTextField
                    required
                    control={control}
                    name='capacity'
                    placeholder='Capacity'
                    disabled={isCheckEnded}
                    label='Capacity'
                    type='number'
                    onChange={(e) => setValue('capacity', parseInt(e.target.value))}
                  />
                </div>
                <div className='col-6 mb-8'>
                  <HookFormTextField
                    required
                    control={control}
                    name='deferred_student'
                    placeholder='Deferred Students'
                    disabled={isCheckEnded}
                    label='Deferred Students'
                    type='number'
                    onChange={(e) => setValue('deferred_student', parseInt(e.target.value))}
                  />
                </div>
                <div className='col-6'>
                  <HookFormTextField
                    required
                    control={control}
                    name='new_student'
                    placeholder='New Students'
                    disabled={isCheckEnded}
                    label='New Students'
                    type='number'
                    onChange={(e) => setValue('new_student', parseInt(e.target.value))}
                  />
                </div>
              </>
            )}
            {roomDetail?.mode === ROOM_MODE.VIRTUAL && (
              <>
                <div className='col-12 mb-8'>
                  <HookFormSelectAntd
                    control={control}
                    name='status'
                    placeholder='Status'
                    disabled={roomDetail?.facility?.status === FACILITY_STATUS_ENUM.CLOSE}
                    label='Status'
                    required
                    showSearch
                    options={FACILITY_STATUS}
                  />
                </div>
                <div className='col-12'>
                  <HookFormTextField
                    control={control}
                    name='virtual_metadata'
                    label='Additional Information'
                    required
                    className='mb-2'
                    placeholder='Please enter information'
                  />
                  <FieldNote className='ms-4'>
                    Please fill in additional information of this classroom, such as email/password
                    of zoom account,…
                  </FieldNote>
                </div>
              </>
            )}
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <ButtonSecondary type='button' title='Cancel' className='me-5' onClick={handleCancel} />
            <ButtonPrimary
              type='submit'
              title='Save'
              disabled={roomDetail?.facility?.status === FACILITY_STATUS_ENUM.CLOSE}
              loading={loading || isLoading}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RoomProfileSetting
