import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { StoryAPI } from 'src/apis/story'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { FILTER_SELECTALL_SORTBY, pageSizeOptions } from 'src/constants'
import { BUTTON_TEXT } from 'src/constants/lang'
import { IStoryList } from 'src/type/story'

import { formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { z } from 'zod'
import HookFormDateTime from '../base/datetime/HookFormDateTime'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import HookFormTextField from '../base/textfield/HookFormTextField'
import { ListViewProvider } from './components/core/ListViewProvider'
import { StoryListToolbar } from './components/header/StoryListToolbar'
import { StoryTable } from './components/table/StoryTable'
import useChecked from 'src/hooks/use-checked'

const { Option } = Select

const fieldNames = ['text', 'sortType', 'fromDate', 'toDate']
const initialValues: any = { text: '', sortType: '', fromDate: '', toDate: '' }

const StoryList = () => {
  const [storyList, setStoryList] = useState<IStoryList>()

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    storyList?.stories.map((row) => {
      return row
    })
  )
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const queryParams = {
    text: searchParams.get('story_name') ?? '',
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }
  const [loading, setLoading] = useState(true)
  const [queryString, setQueryString] = useState('')
  const [currentPage, setCurrentPage] = useState(queryParams.page_index || 1)
  const [pageSize, setPageSize] = useState(queryParams.page_size || pageSizeOptions[0].value)
  const validationSchema = z.object({
    text: z.string().optional(),
    sortType: z.any(),
    fromDate: z.string().optional(),
    toDate: z.string().optional(),
  })

  // Using validate for input
  const { control, getValues, reset, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const navigate = useNavigate()

  const handleChangeParams = (currenPage: number, size: number) => {
    const dateQueryFromDate = getDateInfo(getValues('fromDate'))
    const dateQueryToDate = getDateInfo(getValues('toDate'))
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      story_name: getValues('story_name') ? getValues('story_name') : '',
      ...(getValues('sortType') &&
        getValues('sortType') !== 'all' && {
          sortType: getValues('sortType'),
        }),
      ...(getValues('fromDate') && {
        fromDate: formatISOFromDate(
          dateQueryFromDate.year,
          dateQueryFromDate.month,
          dateQueryFromDate.day
        ).toISOString(),
      }),
      ...(getValues('toDate') && {
        toDate: formatISOToDate(
          dateQueryToDate.year,
          dateQueryToDate.month,
          dateQueryToDate.day
        ).toISOString(),
      }),
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
    setQueryString(queryString)
  }

  const fetchStoryList = async (currentPage: number, pageSize: number, queryString?: string) => {
    try {
      const res = await StoryAPI.getAPIStory({
        page_index: currentPage,
        page_size: pageSize,
        // params: params,
        queryString: queryString,
      })
      setStoryList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = () => {
    const dateQueryFromDate = getDateInfo(getValues('fromDate'))
    const dateQueryToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = {
      page_index: currentPage,
      page_size: pageSize,
      story_name: getValues('story_name') ? getValues('story_name') : '',
      ...(getValues('sortType') &&
        getValues('sortType') !== 'all' && {
          sortType: getValues('sortType'),
        }),
      ...(getValues('fromDate') && {
        fromDate: formatISOFromDate(
          dateQueryFromDate.year,
          dateQueryFromDate.month,
          dateQueryFromDate.day
        ).toISOString(),
      }),
      ...(getValues('toDate') && {
        toDate: formatISOToDate(
          dateQueryToDate.year,
          dateQueryToDate.month,
          dateQueryToDate.day
        ).toISOString(),
      }),
    }

    const query = Object.entries(cleanedParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    setLoading(true)
    fetchStoryList(1, cleanedParams.page_size || 10, query)
    handleChangeParams(1, queryParams.page_size || 10)
    toggleCheckAll(false)
    setCurrentPage(1)
  }

  const onReset = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setLoading(true)
    navigate('')
    // navigate(PageLink.STORY)

    fetchStoryList(1, 10, '')
    toggleCheckAll(false)
    setCurrentPage(1)
    setPageSize(10)
  }

  return (
    <div className='card'>
      <div className='card-header border-0 pt-10'>
        <div className='container sapp-height-min--content'>
          <div className='row'>
            <div className='col-xl-3 col-sm-4 ps-0'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
                <HookFormTextField
                  control={control}
                  name='story_name'
                  placeholder='Search Case Study'
                  defaultValue={queryParams.text ?? ''}
                  onSubmit={onSubmit}
                  isListScreen
                />
              </div>
            </div>
            <div className='col-xl-3 col-sm-4'>
              <HookFormSelectAntd
                name='sortType'
                placeholder='Sort by'
                control={control}
                size='large'
                defaultValue={queryParams?.sortType ?? ''}
                classNameHeight='sapp-h-40'
                options={FILTER_SELECTALL_SORTBY}
              />
            </div>
            <div className='col-xl-3 col-sm-4'>
              <HookFormDateTime
                control={control}
                name='fromDate'
                placeholder='From date'
                defaultValue={queryParams.fromDate ?? ''}
                isListScreen
              />
            </div>
            <div className='col-xl-3 col-sm-4 pe-xl-0 pe-3 ps-xl-3 ps-0 mt-3 mt-xl-0'>
              <HookFormDateTime
                control={control}
                name='toDate'
                placeholder='To date'
                defaultValue={queryParams.toDate ?? ''}
                isListScreen
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-6'>
        <div className='d-flex'>
          <SAPPFIlterButton
            okClick={onSubmit}
            resetClick={onReset}
            titleReset='Reset'
            titleSubmit={BUTTON_TEXT.SEARCH}
            disabled={loading}
            loading={loading}
          />
        </div>
        <>
          <StoryListToolbar
            searchTerm={watch('story_name')}
            sortSortBy={watch('sortType')}
            fromDate={watch('fromDate')}
            toDate={watch('toDate')}
          />
        </>
      </div>
      <StoryTable
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        storyList={storyList}
        loading={loading}
        setLoading={setLoading}
        handleChangeParams={handleChangeParams}
        fetchStoryList={fetchStoryList}
        queryString={queryString}
        pageSize={pageSize}
        setPageSize={setPageSize}
        checkedList={checkedList}
        toggleCheck={toggleCheck}
        toggleCheckAll={toggleCheckAll}
        isCheckedAll={isCheckedAll}
      />
    </div>
  )
}

const StoryListWrapper = () => (
  <ListViewProvider>
    <StoryList />
  </ListViewProvider>
)

export { StoryListWrapper }
