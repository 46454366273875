import { createRequiredContext } from 'src/helper/createRequiredContext'
import { IDepartmentTree } from 'src/type'

interface IDepartmentProvider {
  data?: IDepartmentTree
  isLoading?: boolean
  id: string
}

export const [DepartmentProvider, useDepartment] = createRequiredContext<IDepartmentProvider>()
