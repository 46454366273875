import DeptartmentDetail from 'src/components/departments/DepartmentDetail'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink, TITLE } from 'src/constants'
import { ITabs } from 'src/type'

const DepartmentDetailPage = () => {
  const breadcrumbs: ITabs[] = [
    {
      link: `${PageLink.DASHBOARD}`,
      title: 'LMS',
    },
    {
      link: `${PageLink.DEPARTMENTS}`,
      title: TITLE.DEPARTMENTS,
    },
    {
      link: '',
      title: TITLE.DEPARTMENTS_DETAIL,
    },
  ]
  return (
    <PageLayouts pageTitle={`Department Detail`} breadcrumbs={breadcrumbs}>
      <DeptartmentDetail />
    </PageLayouts>
  )
}

export default DepartmentDetailPage
