import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { capitalize } from 'lodash'
import { IResponse } from 'preview-activity/dist/shared/interfaces'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { ResourceAPI } from 'src/apis/resource-bank'
import { ResourcesAPI } from 'src/apis/resources'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { BUTTON_TEXT } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_RESOURCES_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { Role } from 'src/type'
import {
  IResource,
  IResourceList,
  ISearchResources,
  RESOURCE_STATUS_LABEL,
  RESOURCE_TYPE,
  SUFFIX_TYPE_LABEL,
  defaultSearchResources,
} from 'src/type/resource'
import { truncateString } from 'src/utils/string'
import { z } from 'zod'
import ListGrouping from '../base/list-grouping/ListGrouping'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import HookFormTextField from '../base/textfield/HookFormTextField'
import { SUFFIX_TYPE } from '../base/upload-file/ModalUploadFile/UploadFileInterface'
import VideoSetting from '../courses/course-detail/create-tabs/tabVideo/videoSetting'
import ButtonIconPrimary from '../ui/button-icon-primary/ButtonIconPrimary'
import CardResource from './CardResource'
import ResourceVideoSetting from './ResourceUploadFile/ResourceVideoSetting'
import TableResource from './TableResource'
const { Option } = Select

function FolderList() {
  // TODO: state của list resource
  const [resourceList, setResourceList] = useState<IResourceList>()
  // TODO: state để lấy tổng items và tổng size của file
  const [totalSize, setTotalSize] = useState<any>()
  // TODO: state này để  set trạng thái loading
  const [loading, setLoading] = useState(false)
  // TODO: dùng để lấy số item đã được check ở checkbox
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll, listDataChecked } =
    useChecked<any>(resourceList?.resources)

  const [openVideoSetting, setOpenVideoSetting] = useState<{
    status: boolean
    resource_id?: string
    queryPrams?: ISearchResources
    fileBreadcrumb?: {
      name: string
      id: string
      parent_id: string
    }[]
    isEdit?: boolean
  }>({
    status: false,
  })

  // TODO: state dùng để đóng mở trạng thái tạo folder mới
  const [newFolder, setNewFolder] = useState(false)
  const navigate = useNavigate()
  const [resourceId, setResourceId] = useState('')
  const { profileMe } = useUserContext()
  const allowRenderCreateResources = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.CREATE_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderDownloadResources = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.DOWNLOAD_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderRemoveResources = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_RESOURCES_GR.REMOVE_RESOURCES) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const [fileBreadcrumb, setFileBreadcrumb] = useState<
    { name: string; id: string; parent_id: string }[]
  >([])

  // TODO: state này để mở popup confirm
  const { confirm, contextHolder } = useConfirm()

  const { search } = useLocation()

  // TODO: hàm này để gọi API để lấy danh sách resource
  const gotoPage = async (params: ISearchResources) => {
    const queryParams: { [key: string]: any } = handleGetQueryParams()
    const filteredParams = Object.keys(defaultSearchResources).reduce((previousValue, key) => {
      const paramValue = params?.[key as keyof ISearchResources]
      if (paramValue === undefined) {
        previousValue[key] = queryParams?.[key]?.toString().trim() || undefined
      } else if (paramValue === '') {
        previousValue[key] = undefined
      } else {
        previousValue[key] = paramValue?.toString().trim()
      }
      return previousValue
    }, {} as { [key: string]: any })

    filteredParams.status =
      watch('suffix_types') === SUFFIX_TYPE.VIDEO ? filteredParams.status : undefined

    if (params.page_index === 1) {
      filteredParams.page_index = 1
    }
    handleChangeParams({
      ...filteredParams,
    })
  }

  const fetchGetResource = async () => {
    setLoading(true)
    const params = handleGetQueryParams()

    const { name, ...other } = params
    try {
      const response = await getResource({ ...other, load_time: Date.now().toString() })
      if (response.success) {
        setResourceList(response?.data)
        await getLocation(params.parent_id || 'null')
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const getResource = async (params: ISearchResources): Promise<IResponse<IResourceList>> => {
    try {
      const response = await ResourceAPI.getResource({ ...params, dateField: 'updated_at' })
      if (
        response.data?.meta?.total_pages &&
        params.page_index &&
        params.page_index > 1 &&
        params.page_index > response.data.meta.total_pages &&
        !response.data?.resources?.[0]
      ) {
        return await getResource({ ...params, page_index: response.data.meta.total_pages || 1 })
      }
      return response
    } catch (error) {
      throw error
    }
  }

  const getLocation = async (parent_id?: string) => {
    if (parent_id && parent_id !== 'null') {
      try {
        const response = await ResourceAPI.getResourceParents(parent_id)
        if (response.success) {
          setFileBreadcrumb(response.data?.reverse())
        }
      } catch (error) {}
    } else {
      setFileBreadcrumb([])
    }
  }

  // TODO: hàm này để lấy tổng size
  const fetchGetTotalSize = async () => {
    await ResourceAPI.getTotalSize().then((res) => setTotalSize(res?.data))
  }

  const deleteResource = async (id: string[]) => {
    try {
      const response = await ResourceAPI.deleteResource(id).finally(() => {
        gotoPage({})
      })
      const errorData = response?.data?.data?.data

      if (response?.success === true) {
        if (errorData?.length > 0) {
          toast.error('Resource: ' + errorData.join(`, `) + " can't be deleted!")
        } else {
          toast.success(capitalize(response?.data?.message))
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      toggleCheckAll(false)
      fetchGetResource()
    }
  }

  const downloadResources = async (resource: IResource[]) => {
    let hasFolder = false
    const files = resource?.reduce((previousValue, current) => {
      if (current.resource_type === 'FILE') {
        const file = {
          name: current.name || '',
          file_key: current.file_key || '',
        }
        previousValue.push(file)
      } else {
        hasFolder = true
      }
      return previousValue
    }, [] as { name: string; file_key: string }[])

    if (!files?.[0]) {
      toast.error('You have not selected any files!')
    }
    if (hasFolder) {
      toast.error('Dạng folder sẽ không được download!')
    }
    try {
      ResourcesAPI.downloadFile({ files })
      toggleCheckAll(false)
    } catch (error: any) {
      console.error(error)
    }
  }

  // Validate for input
  const validationSchema = z.object({
    search_key: z.string().optional(),
  })

  // Using validate for input
  const { control, reset, getValues, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  // TODO: hàm này dùng để đóng folder
  const handleCloseCreateFolder = () => {
    setNewFolder(false)
  }

  useEffect(() => {
    setValue('search_key', '')
    setValue('suffix_types', '')
    setValue('status', '')
    fetchGetTotalSize()
    gotoPage({})
  }, [])

  useEffect(() => {
    if (!search.length) return
    const searchParams = new URLSearchParams(search)
    const search_key = searchParams.get('search_key') as string
    setValue('search_key', search_key || '')
    const suffix_types = searchParams.get('suffix_types') as string
    setValue('suffix_types', suffix_types || '')
    const status = searchParams.get('status') as string
    setValue('status', status || '')
    fetchGetResource()
  }, [search])

  const handleChangeParams = (params: ISearchResources) => {
    let url = new URL(window.location.href)
    Object.entries(params).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        url.searchParams.delete(key)
      } else {
        url.searchParams.set(key, value)
      }
    })
    if (url.search) {
      navigate(url.search)
    } else {
      navigate('')
    }
  }
  const handleGetQueryParams = () => {
    const searchParams = new URLSearchParams(search)

    const queryParams = Object.keys(defaultSearchResources).reduce((previousValue, key) => {
      const value = searchParams.get(key)
      if (
        (value === undefined || value === null) &&
        key !== 'page_index' &&
        key !== 'page_size' &&
        key !== 'parent_id'
      ) {
        return previousValue
      }
      if (key === 'page_index') {
        previousValue[key] = parseInt(value as string) || 1
      } else if (key === 'page_size') {
        previousValue[key] = parseInt(value as string) || 10
      } else if (key === 'parent_id') {
        ;(previousValue[key as keyof ISearchResources] as any) = value || 'null'
      } else {
        ;(previousValue[key as keyof ISearchResources] as any) = value
      }

      if (previousValue['search_key'] || previousValue['suffix_types']) {
        delete previousValue['parent_id']
      } else {
        delete previousValue['resource_type']
      }

      return previousValue
    }, {} as ISearchResources)

    return queryParams
  }

  const handlePaginationChange = (page_index: number, page_size: number) => {
    gotoPage({ page_index, page_size })
  }

  const onSubmit = async () => {
    const payload: ISearchResources = {
      search_key: getValues('search_key')?.trim() || '',
      page_index: 1,
      status: getValues('status') || '',
      suffix_types: getValues('suffix_types') || '',
    }

    if (payload.search_key || payload.suffix_types) {
      payload.resource_type = RESOURCE_TYPE.FILE
    }
    payload.parent_id = 'null'

    await gotoPage(payload)
  }

  const onReset = async () => {
    reset()
    setValue('search_key', '')
    gotoPage(defaultSearchResources)
    toggleCheckAll(false)
  }

  const handleUploadFile = () => {
    setOpenVideoSetting({
      status: true,
      queryPrams: handleGetQueryParams(),
      fileBreadcrumb,
      isEdit: false,
    })
  }
  const filesChecked = useMemo(() => {
    return listDataChecked.reduce(function (accumulator, currentValue) {
      // Kiểm tra nếu phần tử hiện tại có resource_type là 'FILE'
      if (currentValue.resource_type === 'FILE') {
        // Thêm id của phần tử hiện tại vào mảng tích lũy
        accumulator.push(currentValue.id)
      }
      // Trả về mảng tích lũy cho lần lặp tiếp theo
      return accumulator
    }, [])
  }, [listDataChecked])

  const handleEditResourceVideo = (resource: IResource) => {
    setOpenVideoSetting({
      status: true,
      resource_id: resource.id,
      queryPrams: handleGetQueryParams(),
      isEdit: true,
    })
  }

  const suffix_types = watch('suffix_types')

  return (
    <>
      {contextHolder}
      <div>
        <CardResource totalSize={totalSize} />
        <div className='card card-flush'>
          <div className='card-header pt-8'>
            <div className='card-title my-0'>
              <Row>
                <Col>
                  <div className='w-200px'>
                    <HookFormTextField
                      name='search_key'
                      placeholder='Search'
                      control={control}
                      isListScreen
                      onSubmit={onSubmit}
                    />
                  </div>
                </Col>
                <Col>
                  <div className='w-200px'>
                    <HookFormSelectAntd
                      size='large'
                      control={control}
                      name='suffix_types'
                      placeholder='Type'
                      classNameHeight='sapp-h-40'
                      options={SUFFIX_TYPE_LABEL}
                    />
                  </div>
                </Col>
                {watch('suffix_types') === SUFFIX_TYPE.VIDEO && (
                  <Col>
                    <div className='w-200px'>
                      <HookFormSelectAntd
                        size='large'
                        control={control}
                        name='status'
                        placeholder='Status'
                        classNameHeight='sapp-h-40'
                        options={RESOURCE_STATUS_LABEL}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div className='d-flex justify-content-end'>
              {checkedList?.length > 0 ? (
                <>
                  {allowRenderRemoveResources && (
                    <div className='me-3'>
                      <ListGrouping
                        selected={checkedList}
                        okClick={() => deleteResource(checkedList)}
                        title='Delete'
                        body='Are you sure you want to delete selected files or folders?'
                        okButtonCaption='Yes, delete!'
                      />
                    </div>
                  )}
                  {allowRenderDownloadResources && (
                    <div className='me-3'>
                      {filesChecked?.[0] && (
                        <ListGrouping
                          showSelected={false}
                          type='primary'
                          selected={filesChecked}
                          okClick={() => downloadResources(listDataChecked)}
                          title='Download'
                          body='Are you sure you want to delete selected files or folders?'
                          okButtonCaption='Yes, delete!'
                          isConfirm={false}
                        />
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {allowRenderCreateResources && (
                    <div>
                      <ButtonIconPrimary
                        iconName='add-folder'
                        title='New Folder'
                        iconType='outline'
                        onClick={() => {
                          setNewFolder(true)
                          setResourceId('')
                        }}
                        size='small'
                        className='me-3'
                      />
                      <ButtonIconPrimary
                        iconName='folder-up'
                        title='Upload Files'
                        iconType='outline'
                        onClick={handleUploadFile}
                        size='small'
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className='card-header border-0 pt-5'>
            <div className='d-flex mb-3'>
              <SAPPFIlterButton
                titleReset='Reset'
                okClick={onSubmit}
                resetClick={onReset}
                titleSubmit={BUTTON_TEXT.SEARCH}
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>
          <div className='card-body'>
            <div className='d-flex flex-stack'>
              <div className='badge badge-lg badge-light-primary'>
                <div className='d-flex align-items-center flex-wrap'>
                  <KTIcon
                    iconName='abstract-32'
                    iconType='outline'
                    className='fs-2 text-primary me-3'
                  ></KTIcon>
                  <div
                    className='sapp-cursor-pointer'
                    onClick={() => {
                      gotoPage(defaultSearchResources)
                    }}
                  >
                    SAPP
                  </div>

                  {fileBreadcrumb?.map((e) => {
                    return (
                      <Fragment key={e.id}>
                        <KTIcon
                          iconName='right'
                          iconType='outline'
                          className='fs-2 text-primary mx-1'
                        ></KTIcon>
                        <div
                          className='sapp-cursor-pointer'
                          onClick={() => {
                            gotoPage({ parent_id: e.id, name: e.name })
                          }}
                        >
                          {truncateString(e.name || '', 30)}
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              </div>
              <div className='badge badge-lg badge-primary'>
                <span id='kt_file_manager_items_counter'>
                  {resourceList?.meta?.total_records} items
                </span>
              </div>
            </div>

            <TableResource
              suffix_types={suffix_types}
              loading={loading}
              resourceList={resourceList}
              isCheckedAll={isCheckedAll}
              toggleCheckAll={toggleCheckAll}
              newFolder={newFolder}
              handleCloseCreateFolder={handleCloseCreateFolder}
              checkedList={checkedList}
              toggleCheck={toggleCheck}
              confirm={confirm}
              deleteResource={deleteResource}
              handlePaginationChange={handlePaginationChange}
              setLoading={setLoading}
              setNewFolder={setNewFolder}
              goToPage={gotoPage}
              resourceId={resourceId}
              setResourceId={setResourceId}
              handleEditResourceVideo={handleEditResourceVideo}
              fileBreadcrumb={fileBreadcrumb}
              fetchGetResource={fetchGetResource}
            />
          </div>
        </div>
      </div>
      {openVideoSetting.isEdit ? (
        <VideoSetting
          open={openVideoSetting.status}
          setOpen={setOpenVideoSetting}
          resource_id={openVideoSetting.resource_id}
        ></VideoSetting>
      ) : (
        <ResourceVideoSetting
          open={openVideoSetting}
          setOpen={setOpenVideoSetting}
          gotoPage={gotoPage}
          fetchGetResource={fetchGetResource}
        ></ResourceVideoSetting>
      )}
    </>
  )
}

export default FolderList
