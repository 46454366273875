import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { EventTestAPI } from 'src/apis/event-test'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HeaderEventParticipant from 'src/components/event-test/HeaderEventParticipant'
import TableEventParticipants from 'src/components/event-test/TableEventParticipants'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import { ITabs } from 'src/type'
import { cleanParamsAPI } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: PageLink.EVENT_TEST,
    title: 'Event Tests',
  },
  {
    link: '',
    title: LANG_SIDEBAR.allParticipant,
  },
]
const fieldNames = [
  'name',
  'course_category_id',
  'course_level_id',
  'event_id',
  'event_phase_id',
  'sortType',
  'fromDate',
  'toDate',
  'status',
]
const initialValues: any = {
  name: '',
  course_category_id: '',
  course_level_id: '',
  event_id: '',
  sortType: '',
  fromDate: '',
  toDate: '',
  status: '',
  quizType: 'ENTRANCE_TEST',
}
const EventParticipantsList = () => {
  const [EntranceTestList, setEntranceTestList] = useState<any>({
    participants: [
      {
        quiz: {},
      },
    ],
    metadata: {},
  })
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const searchParams = new URLSearchParams(search)
  const [queryParamsChanged, setQueryParamsChanged] = useState(false)

  const queryParams = {
    text: searchParams.get('text') ?? '',
    course_category_id: searchParams.get('course_category_id') ?? '',
    sortType: searchParams.get('sortType'),
    quiz_id: searchParams.get('quiz_id'),
    event_id: searchParams.get('event_id'),
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }
  const { control, getValues, reset, setValue, watch } = useForm<any>()

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: getValues('text'),
      course_category_id: replaceValueAll(getValues('course_category_id')),
      event_id: replaceValueAll(getValues('event_id')) ?? '',
      quiz_id: replaceValueAll(getValues('quiz_id')) ?? '',
      sortType: replaceValueAll(getValues('sortType')),
    }
    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const filterParams = cleanParamsAPI({
    text: queryParams.text ?? '',
    course_category_id: queryParams.course_category_id ?? '',
    event_id: queryParams.event_id ?? '',
    quiz_id: queryParams.quiz_id ?? '',
    sortType: queryParams.sortType,
  })
  const getParams = (
    text: string,
    course_category_id: string,
    event_id?: string,
    quiz_id?: string,
    sortType?: string,
    quizType?: any,
    dateField?: string
  ) => ({
    text,
    course_category_id,
    event_id,
    quiz_id,
    sortType,
    quizType,
    dateField,
  })
  const { toggleCheckAll } = useChecked<any>(EntranceTestList?.classes)
  useEffect(() => {
    fetchParticipantList(1, queryParams.page_size || 10)
  }, [])
  useEffect(() => {
    if (queryParamsChanged) {
      fetchParticipantList(1, queryParams.page_size || 10)
      setQueryParamsChanged(false)
    }
  }, [queryParamsChanged])

  const fetchParticipantList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await EventTestAPI.getParticipants({
        page_index: currentPage,
        page_size: pageSize,
        params: {
          ...params, // Mở rộng các prop từ đối tượng params
          quizType: 'EVENT_TEST',
          dateField: 'updated_at',
        } as Record<string, any>,
      })
      setEntranceTestList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
    return
  }
  const onSubmit = () => {
    const nameValue = getValues('text')
    const trimmedName = nameValue ? nameValue.trimStart().trimEnd() : ''
    const fromDateValue = getValues('fromDate')
    const toDateValue = getValues('toDate')
    const fromDateParam = fromDateValue
      ? typeof fromDateValue === 'string'
        ? fromDateValue
        : fromDateValue.toISOString()
      : ''

    const toDateParam = toDateValue
      ? typeof toDateValue === 'string'
        ? toDateValue
        : toDateValue.toISOString()
      : ''

    const cleanedParams = cleanParamsAPI(
      getParams(
        trimmedName,
        replaceValueAll(getValues('course_category_id')),
        replaceValueAll(getValues('event_id')),
        replaceValueAll(getValues('quiz_id')),
        replaceValueAll(getValues('sortType')),
        fromDateParam,
        toDateParam
      )
    )

    setLoading(true)

    fetchParticipantList(1, queryParams.page_size || 10, cleanedParams)

    handleChangeParams(1, queryParams.page_size || 10)
  }

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
      navigate(PageLink.EVENT_PARTICIPANT_LIST)
    })
    setLoading(true)
    fetchParticipantList(1, 10)
  }

  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.allParticipant} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <HeaderEventParticipant
          control={control}
          onSubmit={onSubmit}
          setValue={setValue}
          watch={watch}
          handleResetFilter={handleResetFilter}
          queryParams={queryParams}
          loadingTable={loading}
        />
        <TableEventParticipants
          EntranceTestList={EntranceTestList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchParticipantList={fetchParticipantList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          toggleCheckAll={toggleCheckAll}
        />
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(EventParticipantsList)
