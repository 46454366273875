import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import ItemProfile from 'src/components/base/ItemProfile'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import { MY_PROFILE, PROGRAM, STUDENT_PROFILE } from 'src/constants'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { userKeys } from 'src/constants/queryKeyFactory'
import { useStudentContext } from 'src/context/StudentContext'
import { useUserContext } from 'src/context/UserProvider'
import { ITabs, Role } from 'src/type'
import { IHubspotSubjects } from 'src/type/students'
import '../StudentProfile.scss'
import StudentGeneralInfo from './StudentGeneralInfo'
import StudentProgramOverview from './StudentProgramOverview'

const StudentProfileDetail = () => {
  const { studentDetail, isLoading } = useStudentContext()
  enum PATH {
    GENERAL = 'GENERAL',
  }
  const { id } = useParams()
  const { profileMe, makeUseContactDefault } = useUserContext()
  const overviewPath = `${STUDENT_PROFILE}/${id}/overview`
  const path = {
    generalInfo: `${overviewPath}/${PATH.GENERAL.toLocaleLowerCase()}`,
    cfa: `${overviewPath}/${PROGRAM.CFA.toLocaleLowerCase()}`,
    acca: `${overviewPath}/${PROGRAM.ACCA.toLocaleLowerCase()}`,
    cma: `${overviewPath}/${PROGRAM.CMA.toLocaleLowerCase()}`,
  }

  const isGeneralTab = location.pathname === path.generalInfo
  /**
   * Sắp xếp mảng người dùng theo thời gian tạo và is_default.
   *
   * @param {Array} users - Mảng người dùng cần sắp xếp.
   * @returns {Array} - Mảng đã sắp xếp theo is_default và created_at.
   */
  const sortByCreatedAtAndDefault = (users: Array<any>) => {
    const sortedUsers = [...users]

    sortedUsers.sort((a, b) => {
      if (a.is_default && !b.is_default) return -1
      if (!a.is_default && b.is_default) return 1

      const dateA = new Date(a.created_at)
      const dateB = new Date(b.created_at)

      return dateB.getTime() - dateA.getTime()
    })

    return sortedUsers
  }

  const allowRenderMakeDefault = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.MAKE_CONTACT_DEFAULT_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRender = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.EDIT_USER_GR) ||
      role.permissions?.includes(TITLE_GR.MAKE_CONTACT_DEFAULT_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const tabs: ITabs[] = [
    {
      link: path.generalInfo,
      title: 'General Information',
    },
    {
      link: path.cfa,
      title: PROGRAM.CFA,
    },
    {
      link: path.acca,
      title: PROGRAM.ACCA,
    },
    {
      link: path.cma,
      title: PROGRAM.CMA,
    },
  ]

  const programs = [PROGRAM.CFA, PROGRAM.ACCA, PROGRAM.CMA]
  const program = programs.find((p) => {
    const key = p.toLowerCase() as keyof typeof path

    return location.pathname.startsWith(path[key])
  })

  const fetchHubspotSubject = async () => {
    const res = await UsersAPI.getHubspotSubjects({
      course_category_name: program!,
      sort: {
        name: 'ASC',
      },
    })
    return res
  }

  const { data: hubspotSubjects } = useQuery<IHubspotSubjects>({
    queryKey: userKeys.hubspotSubject(program),
    queryFn: fetchHubspotSubject,
    refetchOnWindowFocus: false,
    enabled: !!program,
  })

  const renderOverviewTabs = () => {
    if (!program) {
      return <StudentGeneralInfo />
    }
    return <StudentProgramOverview program={program} hubspotSubjects={hubspotSubjects} />
  }

  return (
    <>
      {isLoading ? (
        <LoadingProfileDetail />
      ) : (
        <div>
          <div className='card mb-5 mb-xl-10'>
            <div className={`card-header align-items-start pt-5 pb-4`}>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
                {tabs.map((tab) => (
                  <li className='nav-item' key={tab.title}>
                    <Link
                      className={`${
                        location.pathname.includes(tab.link)
                          ? 'active sapp-text--tabs'
                          : 'text-gray-500'
                      } nav-link sapp-fs-14 pt-0 pb-4 ms-0 me-10`}
                      to={tab.link}
                    >
                      {tab.title}
                    </Link>
                  </li>
                ))}
              </ul>
              {isGeneralTab && (
                <ButtonPrimary
                  title={studentDetail?.status !== 'MERGED' && allowRender ? 'Edit Profile' : ''}
                  link={`${STUDENT_PROFILE}/${id}/${MY_PROFILE.SETTING}/general`}
                  size='small'
                />
              )}
            </div>
            {renderOverviewTabs()}
          </div>

          {location.pathname === path.generalInfo &&
            sortByCreatedAtAndDefault(studentDetail?.user_contacts || [])?.map((e, i) => {
              return (
                <div className='card mb-5 mb-xl-10' key={e.id}>
                  <div className='card-header'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bold m-0 '>Profile {i + 1}</h3>
                      {e.is_default && (
                        <div className='badge badge-lg badge-active justify-content-center ms-5'>
                          Default
                        </div>
                      )}
                    </div>

                    {!e.is_default && allowRenderMakeDefault && (
                      <ButtonSecondary
                        title={'Make This Default'}
                        onClick={async () => {
                          try {
                            await makeUseContactDefault({
                              userId: studentDetail?.id || '',
                              userContactId: e.id,
                            })
                            toast.success('Cập nhật Profile mặc định thành công!')
                          } catch (error) {}
                        }}
                        size='small'
                      />
                    )}
                  </div>

                  <div className='card-body p-9'>
                    <div className='d-flex'>
                      <div className='sapp-flex-1 justify-content-between'>
                        {e.email && <ItemProfile title='Email' body={e.email} />}
                        {e.phone && <ItemProfile title='Phone Number' body={e.phone} />}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      )}
    </>
  )
}

export default StudentProfileDetail
