import { Dispatch, SetStateAction, useEffect } from 'react'
import SappDrawer from 'src/components/base/SappDrawer'
import { useForm } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { VALIDATION_FIELD } from 'src/constants'
import { Select } from 'antd'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import MenuTabsSection from 'src/pages/shop/blogs/MenuTabs'
import Advanced from 'src/pages/shop/blogs/Advanced'
import { INewOrUpdatePopup } from 'src/type/shop/common'
import { IEventCategory } from 'src/type/shop/event'
import useShopFilter from 'src/hooks/use-shop-filter'
import { EventAPI } from 'src/apis/short-course/event'
import { z } from 'zod'
import { metaValidationSchema } from 'src/utils/shop/validation'
import { zodResolver } from '@hookform/resolvers/zod'
import toast from 'react-hot-toast'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
const { Option } = Select

interface IProps {
  open: boolean
  handleClose: () => void
  reloadPage: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
  setOpenNewCategory: Dispatch<SetStateAction<INewOrUpdatePopup>>
  type?: 'create' | 'edit'
  categoryData?: IEventCategory
}

type ISubmittedData = {
  title: string
  describe?: string
  slug?: string
  meta_title?: string
  meta_description?: string
  focus_keyword?: string
  robot_meta?: Object
  canonical_url?: string
  parent_id?: string | null
}

const CreateAndUpdateCategory = ({
  open,
  reloadPage,
  handleClose,
  setLoading,
  setOpenNewCategory,
  type = 'create',
  categoryData,
}: IProps) => {
  /**
   * lấy các function trong hookCategory để filter category cha cho category hiện tại
   */
  const { dataList, getData, debounceGetData, handleNextPage } = useShopFilter({
    callback: EventAPI.getEventCategories,
    type: 'event',
    key: 'eventCategories',
  })
  /**
   * Các trường khởi tạo cho HookForm
   */
  const fieldNames = [
    'title',
    'describe',
    'meta_title',
    'meta_description',
    'focus_keyword',
    'robot_meta',
    'canonical_url',
    'parent_id',
    'slug',
  ]
  /**
   * Giá trị khởi tạo cho các trường  HookForm
   */
  const initialValues: any = {
    title: '',
    slug: '',
    describe: '',
    meta_title: '',
    meta_description: '',
    focus_keyword: '',
    robot_meta: {},
    canonical_url: '',
    parent_id: '',
  }
  /**
   * Validation cho các trường trong HookForm
   */
  const validationSchema = z.object({
    title: z.string().min(1, VALIDATION_FIELD),
    slug: z.string().optional(),
    describe: z.string().optional(),
    meta_title: z.string().min(1, VALIDATION_FIELD),
    meta_description: z.string().min(1, VALIDATION_FIELD),
    focus_keyword: z.string().optional(),
    robot_meta: metaValidationSchema,
    canonical_url: z.string().optional(),
    parent_id: z.string().optional(),
  })

  /**
   * Hàm dùng dùng khi khởi tạo form mới hoặc cập nhật lại data của form
   */
  const handleClearData = () => {
    if (type === 'edit' && categoryData) {
      debounceGetData(categoryData.parent?.title ?? '')
      const robotMeta = categoryData?.robot_meta
        ? JSON.parse(JSON.stringify(categoryData?.robot_meta))
        : ''
      setValue('title', categoryData?.title ?? '')
      setValue('slug', categoryData?.slug ?? '')
      setValue('describe', categoryData?.describe ?? '')
      setValue('meta_title', categoryData?.meta_title ?? '')
      setValue('meta_description', categoryData?.meta_description ?? '')
      setValue('focus_keyword', categoryData?.focus_keyword ?? '')
      setValue('robot_meta', categoryData?.robot_meta ?? '')
      setValue('canonical_url', categoryData?.canonical_url ?? '')
      setValue('parent_id', categoryData?.parent_id ?? '')
      Object.keys(robotMeta).forEach((key: string) => {
        setValue(`robot_meta.${key}`, robotMeta[key])
      })
    } else {
      reset()
      fieldNames.forEach((fieldName) => {
        setValue(fieldName, initialValues[fieldName])
      })
    }
  }

  /**
   * Handle khởi tạo Form khi mở popup
   * @param{categoryData} - data của category nếu có
   * @param{open} - Để hanle việc clear Form khi form không có category data
   */
  useEffect(() => {
    // setCategoryList()
    handleClearData()
  }, [categoryData, open])

  const useFormProp = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: initialValues,
  })
  const { control, handleSubmit, reset, setValue, getValues } = useFormProp

  /**
   * Handle submit action of form - xử lý tạo mới hoặc cập nhật
   * @param {ISubmittedData} data  - Data của form
   */
  const onSubmit = async (data: ISubmittedData) => {
    try {
      setLoading(true)
      if (!String(data.parent_id).trim()) delete data.parent_id
      if (type === 'edit' && categoryData) {
        await EventAPI.editEventCategory({ id: categoryData?.id, data })
        toast.success('Category updated successfully!')
      } else {
        await EventAPI.createEventCategory({ data })
        toast.success('Category created successfully!')
      }
      reset()
      handleClearData()
      reloadPage()
      setOpenNewCategory({ isOpen: false, data: null, type: 'create' })
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  return (
    <SappDrawer
      open={open}
      title='Add New Category'
      handleSubmit={handleSubmit(onSubmit)}
      width='50%'
      handleClose={handleClose}
      okButtonCaption='Save'
      rootClassName='create-shop-root'
      classNameHeader='create-shop-header'
    >
      <HookFormTextField
        control={control}
        name='title'
        label='Name'
        required
        className='sapp-h-45px'
      />
      <div className='my-10'>
        <HookFormTextField control={control} name='slug' label='Slug' className='sapp-h-45px' />
      </div>
      <div className='mb-10'>
        <HookFormSelectAntd
          name='parent_id'
          placeholder='None'
          control={control}
          size='large'
          showSearch
          handleNextPage={handleNextPage}
          onSearch={async (e) => {
            debounceGetData(e)
            return
          }}
          label='Parent Category'
          onFocus={async () => {
            if (dataList?.data?.length <= 0) {
              await getData()
              return
            }
          }}
          onChange={async (e: any) => {
            if (e === undefined) {
              debounceGetData()
            }
          }}
          loading={dataList.loading}
          allowClear
          classNameHeight='sapp-h-45px'
          options={dataList.data?.map((e) => {
            return { label: e.name, value: e.id }
          })}
        />
      </div>
      <div className='mb-10'>
        <HookFormEditor
          label='Describe'
          control={control}
          name='describe'
          math={true}
          height={500}
          defaultValue={getValues('describe')}
          resourceLocation={RESOURCE_LOCATION.MEDIA}
          object_id={undefined}
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField
          control={control}
          name='meta_title'
          label='Meta Title'
          className='sapp-h-45px'
          required
        />
      </div>
      <div className='mb-10'>
        <HookFormEditor
          label='Meta Description'
          control={control}
          name='meta_description'
          math={true}
          height={500}
          defaultValue={getValues('meta_description')}
          resourceLocation={RESOURCE_LOCATION.MEDIA}
          object_id={undefined}
          required
        />
      </div>
      <div className='mb-10'>
        <HookFormTextField
          control={control}
          name='focus_keyword'
          label='Focus Keyword'
          className='sapp-h-45px'
        />
      </div>
      <MenuTabsSection
        typeofSection={'Advanced'}
        SectionName={'Advanced'}
        tabs={[
          {
            id: 'tab1',
            title: 'Advanced',
            content: <Advanced useForm={useFormProp} />,
          },
        ]}
      />
    </SappDrawer>
  )
}

export default CreateAndUpdateCategory
