import { useForm } from 'react-hook-form'
import { Select } from 'antd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { FaqsAPI } from 'src/apis/short-course/faqs'
import useShopFilter, { IFilter } from 'src/hooks/use-shop-filter'
import { useEffect, useState } from 'react'
import { IFAQ, IFAQDetail } from 'src/type/shop/faqs'
import SappLabel from 'src/components/base/label/SappLabel'
import { FAQ_STATUS, PageLink, VALIDATION_FIELD } from 'src/constants'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { IStep } from 'src/pages/shop/faqs/FaqDetail'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useConfirm } from 'src/hooks/use-confirm'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { IStepProgress } from 'src/type'
const { Option } = Select

interface IFaqForm {
  title: string
  shop_category_id?: string
  describe: string
  status: string
}

interface IProps extends IStepProgress {
  faq?: IFAQDetail
  reload: (id?: string) => void
}

const CreateAndUpdateFAQ = ({ setStep, faq, reload, step }: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const initialValues = {
    title: '',
    shop_category_id: '',
    describe: '',
    status: '',
  }

  /**
   * Validate HookForm
   */
  const validationSchema = z.object({
    title: z.string().min(1, VALIDATION_FIELD),
    describe: z.string().optional(),
    shop_category_id: z.string().optional(),
    status: z.string().min(1, VALIDATION_FIELD),
  })

  /**
   * Handle get Category for Faq
   * @param {callback} callback: api function
   * @param {string} type: type of data to save after calling api
   */
  const { dataList, getData, setDataList, debounceGetData, handleNextPage } = useShopFilter({
    callback: FaqsAPI.getFaqCategories,
    type: 'faq',
    key: 'faqCategories',
  })

  /**
   * Init HookForm
   */
  const { control, handleSubmit, setValue, getValues } = useForm<IFaqForm>({
    mode: 'onSubmit',
    resolver: zodResolver(validationSchema),
    defaultValues: initialValues,
  })

  /**
   * Handle close page
   */
  const handleClose = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(PageLink.SHOP_FAQS),
    })
  }

  /**
   * Handle submit - for updating and creating faq
   */
  const onSubmit = async (data: IFAQ) => {
    try {
      setLoading(true)
      if (!data.shop_category_id) delete data.shop_category_id
      if (faq) {
        await FaqsAPI.editFAQ(faq.id, data)
        toast.success('Faqs updated successfully!')
        navigate(`${PageLink.FAQ_DETAIL}/${faq.id}`)
      } else {
        const res = await FaqsAPI.createFAQ(data)
        toast.success('Faqs created successfully!')
        navigate(`${PageLink.FAQ_DETAIL}/${res.data.id}`)
      }
      setStep((prev: IStep[]) =>
        prev.map((step: IStep, index: number) => ({
          ...step,
          status: index === prev.length - 1 ? 'current' : 'active',
        }))
      )
      reload(faq?.id)
    } catch (err) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  /**
   * Init data if the action is update
   */
  const initData = async () => {
    if (faq) {
      if (faq?.shop_faq_category?.id) {
        setDataList((prev: IFilter | any) => {
          return {
            ...prev,
            data: [
              {
                id: faq?.shop_faq_category.id,
                name: faq?.shop_faq_category?.title,
              },
            ],
          }
        })
      }
      setValue('title', faq?.title ?? '')
      setValue('describe', faq?.describe ?? '')
      setValue('status', faq.status)
      setValue('shop_category_id', faq.shop_category_id)
    } else {
      debounceGetData()
    }
  }

  useEffect(() => {
    initData()
  }, [faq])

  return (
    <>
      {contextHolder}
      <div className='form d-flex flex-column flex-lg-row'>
        <Processbar
          step={step}
          setNewStep={setStep}
          showCancel
          onCancel={handleClose}
          onClick={handleSubmit(onSubmit)}
        />
        <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-8'>
          <div className='card card-flush sapp-pt--image'>
            <div className='card-header pt-8 align-items-start'>
              <SappLabel label='Status' isLabel required />
            </div>

            <div className='card-body text-center px-10 pb-10'>
              <HookFormSelectAntd
                size='large'
                name='status'
                control={control}
                dropdownStyle={{ maxWidth: 1000 }}
                placeholder='Status'
                filterOption={true}
                defaultValue={getValues('status')}
                options={FAQ_STATUS}
              />
            </div>
          </div>
        </div>
        <div className='form d-flex flex-column flex-row-fluid gap-7 gap-lg-5'>
          <div className='tab-content'>
            <div className='tab-pane fade show active'>
              <div className='d-flex flex-column gap-7 gap-lg-10 sapp-mw-950px'>
                <div className='card card-flush'>
                  <div className='card-body pt-10'>
                    <div className='text-gray-800 fw-bold sapp-mb-24px sapp-fs-20px'>General</div>
                    <div className='sapp-mb-24px fv-row'>
                      <HookFormTextField
                        control={control}
                        name='title'
                        label='Title'
                        required
                        className='sapp-h-45px'
                      />
                    </div>
                    <div className='sapp-mb-24px fv-row'>
                      <HookFormSelectAntd
                        name='shop_category_id'
                        placeholder='Choose'
                        control={control}
                        size='large'
                        showSearch
                        handleNextPage={handleNextPage}
                        onSearch={async (e: string) => {
                          debounceGetData(e)
                          return
                        }}
                        label='Parent Category'
                        onFocus={async () => {
                          if (dataList?.data?.length <= 0) {
                            await getData()
                            return
                          }
                        }}
                        onChange={async (e: any) => {
                          if (e === undefined) {
                            debounceGetData()
                          }
                        }}
                        loading={dataList?.loading}
                        allowClear
                        classNameHeight='sapp-h-45px'
                        options={dataList?.data?.map((e) => {
                          return { label: e.name, value: e.id }
                        })}
                      />
                    </div>
                    <div className='sapp-mb-24px fv-row'>
                      <HookFormEditor
                        height={500}
                        label='Describe'
                        name='describe'
                        control={control}
                        className='w-100 fs-6'
                        math={true}
                        defaultValue={getValues('describe')}
                        resourceLocation={RESOURCE_LOCATION.MEDIA}
                        object_id={undefined}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateAndUpdateFAQ
