import { Space } from 'antd'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { EyeIcon } from 'src/common/icons'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HeaderTab from 'src/components/base/HeaderTab'
import ItemProfile from 'src/components/base/ItemProfile'
import Text from 'src/components/base/text/Text'
import { LANG_USER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { useStudentContext } from 'src/context/StudentContext'
import { useUserContext } from 'src/context/UserProvider'
import { Role } from 'src/type'
import styles from '../styles.module.scss'
import StudentCMNDDetail from './StudentCMNDDetail'

const StudentGeneralInfo = () => {
  const [open, setOpen] = useState(false)
  const { id } = useParams()
  const { profileMe } = useUserContext()
  const { studentDetail: studentDetail } = useStudentContext()

  const allowRender = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.EDIT_USER_GR) ||
      role.permissions?.includes(TITLE_GR.MAKE_CONTACT_DEFAULT_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  return (
    <>
      <div className='card-body p-10'>
        <Space direction='vertical' className='w-100' size={'middle'}>
          <div className='card'>
            <HeaderTab title={`Personal Information`} />
            <div className='card-body'>
              <ItemProfile
                title='Full name'
                body={<Text>{studentDetail?.detail?.full_name}</Text>}
              />
              <ItemProfile
                title='Email'
                body={
                  <Text>
                    {studentDetail?.user_contacts?.find((item) => item.is_default)?.email}
                  </Text>
                }
              />
              <ItemProfile
                title='Phone'
                body={
                  <Text>
                    {studentDetail?.user_contacts?.find((item) => item.is_default)?.phone}
                  </Text>
                }
              />
              <ItemProfile
                title='Gender'
                body={<Text>{capitalize(studentDetail?.detail?.sex ?? '')}</Text>}
              />
              <ItemProfile
                title='Date of Birth'
                body={
                  <Text>
                    {studentDetail?.detail?.dob
                      ? dayjs(studentDetail?.detail?.dob).format('DD/MM/YYYY')
                      : ''}
                  </Text>
                }
              />
              <ItemProfile
                align='align-self-center'
                title='CMND/CCCD'
                body={
                  <ButtonIcon
                    title={'View'}
                    customButton='m-0 sapp-student-eye d-flex flex-row align-items-center justify-content-between'
                    onClick={() => setOpen(true)}
                    isListScreen
                    className={styles.buttonIcon}
                  >
                    <div className={styles.viewIcon}>
                      <EyeIcon />
                    </div>
                  </ButtonIcon>
                }
              />
              <ItemProfile
                title={'Main class'}
                body={
                  studentDetail?.main_class ? (
                    <Space size={'small'} direction='horizontal'>
                      {studentDetail?.main_class?.map((item, idx) => (
                        <div key={idx}>
                          <span className='badge badge-lg badge-white badge-outline'>{item}</span>
                        </div>
                      ))}
                    </Space>
                  ) : (
                    <span className='text-gray-1000'>{'Chưa có'}</span>
                  )
                }
              />

              <ItemProfile
                title='Deferred/Retake Class'
                body={
                  studentDetail?.reserve_retook_class ? (
                    <Space size={'small'} direction='horizontal'>
                      {studentDetail?.reserve_retook_class?.map((item, idx) => (
                        <div key={idx}>
                          <span className='badge badge-white badge-outline'>{item}</span>
                        </div>
                      ))}
                    </Space>
                  ) : (
                    <span className='text-gray-1000'>{'Chưa có'}</span>
                  )
                }
              />

              <ItemProfile
                title='University'
                body={<Text>{studentDetail?.detail?.university?.name}</Text>}
              />
              <ItemProfile title='Major' body={<Text>{studentDetail?.detail?.major?.name}</Text>} />
              <ItemProfile
                title='Field of work'
                body={<Text>{studentDetail?.detail?.company_type}</Text>}
              />
              <ItemProfile
                title='Position'
                body={<Text>{studentDetail?.detail?.company_position}</Text>}
              />
            </div>
          </div>
          <div className='card'>
            <HeaderTab title={`Account Information`} />
            <div className='card-body'>
              <ItemProfile
                title='Account Type'
                body={studentDetail?.type_user === 'STUDENT' ? 'Student' : 'Employee'}
              />
              <ItemProfile
                title={
                  studentDetail?.type_user === 'STUDENT'
                    ? LANG_USER.STUDENT_ID
                    : LANG_USER.EMPLOYEE_CODE
                }
                body={
                  <Text className='text-blue'>
                    {studentDetail?.hubspot_contact_id || studentDetail?.employee_code}
                  </Text>
                }
              />
              <ItemProfile title='Username' body={<Text>{studentDetail?.username}</Text>} />
              <ItemProfile title='Status' status={studentDetail?.status} />
              <ItemProfile
                title={LANG_USER.TEST_ACCOUNT}
                body={
                  <Text
                    className={clsx(`badge badge-lg`, {
                      'badge-light-warning': !studentDetail?.is_sapp_operator,
                      'badge-light-active': studentDetail?.is_sapp_operator,
                    })}
                  >
                    {studentDetail?.is_sapp_operator ? 'Yes' : 'No'}
                  </Text>
                }
              />
            </div>
          </div>
        </Space>
      </div>

      <StudentCMNDDetail
        studentDetail={studentDetail}
        open={open}
        setOpen={setOpen}
        allowRender={allowRender}
        id={id}
        profileMe={profileMe}
      />
    </>
  )
}

export default StudentGeneralInfo
