import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DepartmentAPI } from 'src/apis/department'
import { departmentKeys, PageLink } from 'src/constants'
import { useDepartment } from 'src/context/DepartmentContext'
import { FormType, IDepartmentForm } from 'src/type'
import { BUSINESS_UNITS, DEPARTMENT_TYPES } from 'src/type/department/enum'
import DepartmentForm from '../DepartmentForm'

const DepartmentSetting = () => {
  const { data, id } = useDepartment()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutate: updateDepartment } = useMutation({
    mutationFn: (data: IDepartmentForm) => DepartmentAPI.update(id, data),
    onSuccess: (res) => {
      if (res.success) {
        toast.success(`Edit ${res?.data?.name || 'Department'} Successfully`)
        queryClient.invalidateQueries(departmentKeys.detail(id))
        navigate(`${PageLink.DEPARTMENTS}/${id}/overview`)
      }
    },
  })

  const parsedData = {
    name: data?.name,
    description: data?.description,
    parent_id: data?.parent?.id,
    parent_name: data?.parent?.name,
    short_name: data?.short_name,
    type: data?.type as DEPARTMENT_TYPES,
    unit: [BUSINESS_UNITS.HO, ...(data?.unit !== BUSINESS_UNITS.HO ? [data?.unit || ''] : [])],
  }

  return (
    <>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0 '>Edit Department</h3>
        </div>
      </div>
      <div className='p-9'>
        <DepartmentForm data={parsedData} submit={updateDepartment} formType={FormType.EDIT} />
      </div>
    </>
  )
}

export default DepartmentSetting
