import React, { useEffect, useState } from 'react'
import { CoursesAPI } from 'src/apis/courses'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import { CLASS_STATUS_ENUM, CLASS_TYPE } from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { ICourseList } from 'src/type/courses'
import './AddCourseToClass.scss'
import TableCourses from './tableCourses'

interface IProps {
  id?: any
  open: boolean
  setOpen: any
  checkedList: any
  setCheckedList: React.Dispatch<React.SetStateAction<any>>
  typeClass: string
  courseCategoryId?: string
  handleResetListExam?: any
  setCheckedListExam?: React.Dispatch<React.SetStateAction<any[]>>
  checkedListExam?: Array<any>
  subject_id?: string
  program?: string
  subject?: string
}

const AddCourseToClass = ({
  open,
  id,
  setOpen,
  checkedList,
  setCheckedListExam,
  checkedListExam,
  setCheckedList,
  typeClass,
  courseCategoryId,
  handleResetListExam,
  subject_id,
  program,
  subject,
}: IProps) => {
  const handleClose = () => {
    setOpen(false)
  }
  const [courseList, setCourseList] = useState<ICourseList>()
  const [selectCourse, setSelectCourse] = useState<any>([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (open) {
      setSelectCourse(checkedList)
    }
  }, [checkedList, open])

  const getParams = (
    name: string,
    course_category_ids: string,
    subject_id: string,
    status: Array<string>,
    course_types: Array<string>,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string
  ) => ({
    name,
    course_category_ids,
    subject_id,
    status,
    course_types,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })
  const queryParams = {
    name: '',
    course_category_ids: '',
    subject_id: '',
    status: [],
    course_types: [],
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
  }

  const fetchCourseList = async (currentPage: number, pageSize: number, params?: any) => {
    const query = Object.assign(
      { status: [CLASS_STATUS_ENUM.PUBLISH, CLASS_STATUS_ENUM.LOCK] },
      params
    )
    try {
      const res = await CoursesAPI.get({
        page_index: currentPage,
        page_size: pageSize,
        params: query,
      })
      setCourseList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const toggleCheck = (course: any) => {
    setSelectCourse([course])
  }
  const { confirm, contextHolder } = useConfirm()

  const handleSubmit = () => {
    if (id) {
      handleResetListExam()
    }
    setCheckedList(selectCourse)
    handleClose()
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: DESCRIPTION_POPUPCONFIRM,
      onClick: handleClose,
    })
  }

  useEffect(() => {
    if (checkedList.length > 0) {
      setSelectCourse(checkedList)
    }
  }, [checkedList])
  useEffect(() => {
    if (
      setCheckedListExam &&
      checkedListExam &&
      checkedList.length > 0 &&
      checkedList[0]?.course_categories?.length > 0 &&
      checkedListExam[0]?.type !== checkedList[0]?.course_categories[0]?.name &&
      typeClass !== CLASS_TYPE.REVISION.toUpperCase()
    ) {
      setCheckedListExam([])
    }
  }, [checkedList[0]?.code, setCheckedListExam])

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>Add Course</div>
          <div className='d-flex justify-content-between align-items-center'>
            {selectCourse.length > 0 && selectCourse[0] !== null && (
              <div className='fw-bold fs-6 text-primary me-8'>{selectCourse.length} Selected</div>
            )}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0'
              classNameSubmit='fw-bold me-5'
              // loading={loading}
              isListScreen
            />
          </div>
        </div>
        {open && (
          <div className='px-7'>
            <TableCourses
              selectCourse={selectCourse}
              courseList={courseList}
              loading={loading}
              setLoading={setLoading}
              handleChangeParams={() => {}}
              fetchCourseList={fetchCourseList}
              filterParams={{}}
              getParams={getParams}
              queryParams={queryParams}
              checkedList={checkedList}
              toggleCheck={toggleCheck}
              toggleCheckAll={() => {}}
              isCheckedAll={false}
              typeClass={typeClass}
              courseCategoryId={courseCategoryId}
              subject_id={subject_id}
              program={program}
              subject={subject}
            />
          </div>
        )}
      </SappModal>
    </>
  )
}
export default AddCourseToClass
