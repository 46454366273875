import { Select } from 'antd'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { FILTER_SELECTALL_SORTBY } from 'src/constants'
import { IQueryParams } from './AddParticipantTable'
import { UseFormReturn } from 'react-hook-form'

const { Option } = Select

interface IProps {
  useFormProp: UseFormReturn
  queryParams: IQueryParams
  onSubmit: () => void
  handleResetFilter: () => void
  loading: boolean
}

export const AddParticipantFilter = ({
  useFormProp,
  queryParams,
  onSubmit,
  handleResetFilter,
  loading,
}: IProps) => {
  const { control } = useFormProp
  return (
    <>
      <div className='card-header border-0'>
        <div className='w-100'>
          <div className='row'>
            {/* begin:: Search */}
            <div className='col-xl-3 col-sm-4'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                <HookFormTextField
                  control={control}
                  name='text'
                  placeholder='Search'
                  defaultValue={queryParams?.text}
                  onSubmit={onSubmit}
                />
              </div>
            </div>
            {/* end:: Search */}
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormSelectAntd
                name='sortType'
                placeholder='Sort by'
                control={control}
                size='large'
                defaultValue={queryParams?.sortType ?? ''}
                className='sapp-fs-select fs-6'
                options={FILTER_SELECTALL_SORTBY}
              />
            </div>
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormDateTime
                control={control}
                name='fromDate'
                placeholder='From date'
                defaultValue={queryParams?.fromDate}
              />
            </div>
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormDateTime
                control={control}
                name='toDate'
                placeholder='To date'
                defaultValue={queryParams?.toDate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-6 mb-6 px-0'>
        <div className='container m-0'>
          <div className='row'>
            <div className='col-sm-4 col-xl-4 col-lg-8 px-xl-0 px-md-0'>
              <SAPPFIlterButton
                titleReset='Reset'
                titleSubmit='Search'
                okClick={onSubmit}
                resetClick={handleResetFilter}
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
