const StudentCertificate = () => {
  return (
    <div
      className='card'
      style={{
        height: '100vh',
        maxHeight: '300px',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <p className='text-gray-500 fw-bold fs-2'>Coming Soon</p>
    </div>
  )
}

export default StudentCertificate
