import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { CertificatesApi } from 'src/apis/certificates'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import TableCertificate from 'src/components/certificate/TableList'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { LANG_CERTIFICATES, LANG_SIDEBAR } from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import useInfinitePrograms from 'src/hooks/useInfiniteCourseCategory'
import { ITabs } from 'src/type'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: LANG_SIDEBAR.listCertificates,
  },
]
const fieldNames = ['name']
const initialValues: any = {
  name: '',
}
const CertificatePage = () => {
  const [certificateList, setCertificateList] = useState<any>()
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const searchParams = new URLSearchParams(search)
  const queryParams = {
    name: searchParams.get('name') ?? '',
    courseCategoryId: searchParams.get('courseCategoryId'),
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const validationSchema = z.object({
    name: z.string().optional(),
    courseCategoryId: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  const { control, getValues, reset, setValue } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: getValues('name'),
      courseCategoryId: replaceValueAll(getValues('courseCategoryId')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const filterParams = cleanParamsAPI({
    name: queryParams?.name?.trimStart().trimEnd() ?? '',
    courseCategoryId: queryParams.courseCategoryId,
    sortType: queryParams.sortType,
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
  })
  const getParams = (
    name: string,
    courseCategoryId: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string
  ) => ({
    name,
    courseCategoryId,
    sortType,
    fromDate,
    toDate,
    dateField,
  })
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    certificateList?.topics
  )

  const {
    programs,
    refetch: refetchPrograms,
    hasNextPage: hasNextPageProgram,
    fetchNextPage: fetchNextPageProgram,
    debounceSearch,
  } = useInfinitePrograms()

  const fetchCertificateList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await CertificatesApi.get({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setCertificateList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name')?.trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('courseCategoryId')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )

    setLoading(true)

    fetchCertificateList(1, queryParams.page_size || 10, cleanedParams)

    handleChangeParams(1, queryParams.page_size || 10)
  }
  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setLoading(true)
    navigate(PageLink.CERTIFICATES)
    fetchCertificateList(1, 10)
  }

  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.listCertificates} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='card-header border-0 pt-8'>
          <ListFilterLayout className='w-100'>
            <HookFormTextField
              control={control}
              name='name'
              placeholder='Search'
              defaultValue={queryParams?.name}
              onSubmit={onSubmit}
              isListScreen
            />

            <HookFormSelectAntd
              control={control}
              name='courseCategoryId'
              placeholder='Program'
              defaultValue={queryParams.courseCategoryId ?? ''}
              showSearch
              onSearch={(text) => {
                debounceSearch(text)
              }}
              classNameHeight='sapp-h-40'
              handleNextPage={hasNextPageProgram && fetchNextPageProgram}
              onDropdownVisibleChange={(open) => {
                if (open && !programs) {
                  refetchPrograms()
                  return
                }
              }}
              options={
                programs?.map((program) => ({ label: program.name, value: program.id })) ?? []
              }
            />

            <HookFormSelectAntd
              name='sortType'
              placeholder='Sort by'
              control={control}
              defaultValue={queryParams?.sortType ?? ''}
              classNameHeight='sapp-h-40'
              options={FILTER_SELECTALL_SORTBY}
            />

            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />
            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
          </ListFilterLayout>
        </div>
        <div className='card-header border-0 pt-8'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>
          {/* start:: button open modal */}
          <ButtonIconPrimary
            iconName='plus'
            title={LANG_CERTIFICATES.addTemplate}
            onClick={() => navigate(PageLink.CREATE_TEMPLATE)}
            size='small'
          />
          {/* end:: button open modal */}
        </div>
        <TableCertificate
          certificateList={certificateList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchCertificateList={fetchCertificateList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(CertificatePage)
