import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import { PROGRAM, STUDENT_PROFILE } from 'src/constants'
import { userKeys } from 'src/constants/queryKeyFactory'
import { ITabs } from 'src/type'
import { IHubspotSubjects } from 'src/type/students'
import StudentGeneralInfoSetting from './StudentGeneralInfoSetting'
import StudentProgramEditForm from './StudentProgramEditForm'
import { useStudentContext } from 'src/context/StudentContext'

const EditStudentProfile = () => {
  const { id } = useParams()
  const settingPath = `${STUDENT_PROFILE}/${id}/setting`
  const { studentDetail: studentData } = useStudentContext()
  const path = {
    generalInfo: `${settingPath}/general`,
    cfa: `${settingPath}/${PROGRAM.CFA.toLocaleLowerCase()}`,
    acca: `${settingPath}/${PROGRAM.ACCA.toLocaleLowerCase()}`,
    cma: `${settingPath}/${PROGRAM.CMA.toLocaleLowerCase()}`,
  }

  const programs = [PROGRAM.CFA, PROGRAM.ACCA, PROGRAM.CMA]
  const program = programs.find((p) => {
    const key = p.toLowerCase() as keyof typeof path
    return location.pathname.startsWith(path[key])
  })

  const tabs: ITabs[] = [
    {
      link: path.generalInfo,
      title: 'General Information',
    },
    {
      link: path.cfa,
      title: PROGRAM.CFA,
    },
    {
      link: path.acca,
      title: PROGRAM.ACCA,
    },
    {
      link: path.cma,
      title: PROGRAM.CMA,
    },
  ]

  const fetchHubspotSubject = async () => {
    const res = await UsersAPI.getHubspotSubjects({
      course_category_name: program!,
      sort: {
        name: 'ASC',
      },
    })
    return res
  }

  const { data: hubspotSubjects } = useQuery<IHubspotSubjects>({
    queryKey: userKeys.hubspotSubject(program),
    queryFn: fetchHubspotSubject,
    refetchOnWindowFocus: false,
    enabled: !!program,
  })

  const renderOverviewTabs = () => {
    if (!program) {
      return <StudentGeneralInfoSetting />
    }
    return <StudentProgramEditForm program={program} hubspotSubjects={hubspotSubjects} />
  }

  if (!studentData) return <></>
  return (
    <div className='card'>
      <div className={`card-header align-items-start pt-5 pb-4`}>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
          {tabs.map((tab) => {
            return (
              <li className='nav-item' key={tab.title}>
                <Link
                  className={`${
                    location.pathname.includes(tab.link)
                      ? 'active sapp-text--tabs'
                      : 'text-gray-500'
                  } nav-link sapp-fs-14 pt-0 pb-4 ms-0 me-10`}
                  to={tab.link}
                >
                  {tab.title}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
      {renderOverviewTabs()}
    </div>
  )
}

export default EditStudentProfile
