import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'

import { getActToken, getRefreshToken, setActToken, setRefreshToken } from 'src/utils'

import { toast } from 'react-hot-toast'
import { PageLink } from 'src/constants'
import { replaceMessage } from 'src/utils/string'
import exceptions from './en.exceptions.json'

// Variable to track whether the refresh token API has been called
let isRefreshing = false
let refreshSubscribers: ((token: string) => void)[] = []

export const getBaseUrl = () => {
  if (typeof window !== 'undefined') {
    return process.env.REACT_APP_API_PUBLIC
  }

  return process.env.REACT_APP_API_PUBLIC
}

export const request = axios.create({
  baseURL: getBaseUrl(),
})

export const fetcher = (url: string, config: AxiosRequestConfig = {}) =>
  request(url, config)
    .then((res) => {
      return res?.data
    })
    .catch((err) => {
      throw err
    })

request.interceptors.request.use(
  (config: any) => {
    config.headers['Content-Type'] = 'application/json' // Change to your preferred content type
    return config
  },
  (error) => {
    // Handle request error
    return Promise.reject(error)
  }
)

request.interceptors.request.use((config) => {
  config.headers = {
    Authorization: 'Bearer ' + getActToken(),
    ...config.headers,
  }

  return config
})

request.interceptors.response.use(
  function (response) {
    return response
  },
  async (error: any) => {
    const originalRequest = error.config

    if (error.response && error.response.status !== 401) {
      return Promise.reject(error)
    }

    if (
      error.response &&
      error.response.status === 401 &&
      error.config.url !== PageLink.AUTH_LOGIN &&
      error.config.url !== PageLink.AUTH_FORGOTPASSWORD
    ) {
      if (!isRefreshing) {
        isRefreshing = true

        axios(`${process.env.REACT_APP_API_PUBLIC}/auth/rotate`, {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + getRefreshToken(),
          },
        })
          .then((res: any) => {
            const userInfo = res?.data?.data?.tokens
            setActToken(userInfo?.act)
            setRefreshToken(userInfo?.rft)

            // update new token to axios
            request.defaults.headers.common['Authorization'] = `Bearer ${getActToken()}`

            // Callback to unauth API calls
            refreshSubscribers.forEach((callback) => callback(getActToken()))
            refreshSubscribers = []
            isRefreshing = false
          })
          .catch(() => {
            localStorage.clear()
            window.location.href = PageLink.AUTH_LOGIN
          })
      }

      // Return a Promise to wait for the initial API callback
      const retryOriginalRequest = new Promise((resolve) => {
        const subscribeTokenRefresh = (token: string) => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`
          resolve(axios(originalRequest))
        }
        refreshSubscribers.push(subscribeTokenRefresh)
      })

      return retryOriginalRequest
    }
    return Promise.reject(error)
  }
)

const toastExceptions = [
  '400|5020',
  '400|5040',
  '400|5030',
  '400|4204',
  '400|5203',
  '400|4201',
  '403|110014',
  '403|110114',
  '400|150001',
  '400|171305',
]

const formatedExceptions = exceptions.reduce(
  (acc: { [key: string]: string }, { code, message }) => {
    acc[code] = message
    return acc
  },
  {}
)

request.interceptors.response.use(
  function (response: any): any {
    return response
  },
  function (error: any) {
    const errorCode: string = error?.response?.data?.error?.code
    const replacements: Record<string, any> = error?.response?.data?.error?.replacements || {}
    let errorMessage = formatedExceptions[errorCode as keyof typeof formatedExceptions]
    if (
      error?.response?.status !== 422 &&
      error?.response?.status !== 413 &&
      !toastExceptions.includes(errorCode)
    ) {
      if (Object.keys(replacements) && errorMessage) {
        // Thay các biến trong message bằng các giá trị tương ứng
        errorMessage = replaceMessage(errorMessage, replacements)
      }

      toast.error(errorMessage || error?.response?.statusText || error?.message || 'Unknown error!')
    }
    const otherErrors = error?.response?.data?.error?.others
    otherErrors?.length &&
      otherErrors?.forEach((err: any) => {
        if (err?.errors?.length) {
          err?.errors?.forEach((item: { code: string; message: string }) => {
            toast.error(item?.message)
          })
        }
      })
    return Promise.reject(error)
  }
)

export const fetchFormData = ({
  url,
  formData,
  onUploadProgress,
  source,
}: {
  url: string
  formData: FormData
  onUploadProgress?: (progressEvent: any) => void
  source?: CancelTokenSource
}) => {
  return request(url, {
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    cancelToken: source?.token,
    onUploadProgress,
  })
}

export const fetchFormJob = ({ url, jobId }: { jobId: string; url: string }) => {
  return fetcher(`${url}/${jobId}`)
}

export const getJsonData = ({ url }: { url: string }) => {
  return axios.get(url)
}

export const fetchFormPutData = ({
  url,
  formData,
  onUploadProgress,
  source,
}: {
  url: string
  formData: FormData
  onUploadProgress?: (progressEvent: any) => void
  source?: CancelTokenSource
}) => {
  return request(url, {
    method: 'PUT',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    cancelToken: source?.token,
    onUploadProgress,
  })
}
