export const monthsOfYear = Array.from({ length: 12 }, (_, i) => {
  const value = (i + 1).toString().padStart(2, '0') // '01', '02', etc.
  return { label: value, value }
})

export enum PROGRAM {
  ACCA = 'ACCA',
  CFA = 'CFA',
  CMA = 'CMA',
}

export enum Keyboard {
  SLASH = '/',
}

export enum DETAIL_TAB_TITLE {
  OVERVIEW = 'overview',
  SETTING = 'setting',
}

export const COMMON_TEXT = {
  DELETE: 'Delete',
  YES: 'Yes',
  NO: 'No',
}
