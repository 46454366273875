import React, { ReactNode } from 'react'
import clsx from 'clsx'

type Props = {
  children?: ReactNode
  fallback?: ReactNode // Custom fallback value
} & React.HTMLAttributes<HTMLSpanElement>

/**
 * A flexible `Text` component that renders a span element.
 *
 * This component accepts children to render as its content. If no children are provided,
 * a customizable fallback value is displayed instead. It also supports all standard HTML
 * span element attributes.
 *
 * @typedef {Object} Props
 * @property {ReactNode} [children] - The content to be displayed inside the span. If undefined, the fallback value will be used.
 * @property {ReactNode} [fallback='Chưa có'] - The value to display when no children are provided. Defaults to 'Chưa có'.
 * @property {React.HTMLAttributes<HTMLSpanElement>} [props] - Additional HTML span attributes.
 *
 * @param {Props} props - The props for the `Text` component.
 * @returns {JSX.Element} A span element displaying the children or fallback value.
 */
const Text: React.FC<Props> = ({ children, fallback = 'Chưa có', ...props }) => {
  const isEmpty = !children

  return (
    <span
      {...props}
      className={clsx(props.className, {
        'text-gray-1000': isEmpty, // Apply Bootstrap's text-muted when content is empty
      })}
    >
      {isEmpty ? (
        <span className='text-gray-1000'>{fallback}</span> // Ensure fallback is always grey
      ) : (
        children
      )}
    </span>
  )
}

export default Text
