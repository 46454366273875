export enum DEPARTMENT_TYPES {
  BOARD = 'BOARD',
  BLOCK = 'BLOCK',
  DIVISION = 'DIVISION',
  DEPARTMENT = 'DEPARTMENT',
  SUB_DEPARTMENT = 'SUB_DEPARTMENT',
}

export enum BUSINESS_UNITS {
  ACCA = 'ACCA',
  CFA = 'CFA',
  CMA = 'CMA',
  SHORT_COURSE = 'SHORT_COURSE',
  HO = 'HO',
}
