import { isEmpty } from 'lodash'
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { StaffAPI } from 'src/apis/staffs'
import { VerifyEmailOtp } from 'src/apis/type'
import { UsersAPI } from 'src/apis/user'
import { AuthAPI } from 'src/apis/user/auth'
import { ErrorCode } from 'src/constants'
import { IForgotPassword } from 'src/type'
import { IStudentDetail } from 'src/type/students'
import { getActToken, removeJwtToken, setActToken, setRefreshToken } from 'src/utils'

// type for context
type Context = {
  loginUsername: (username: string, password: string) => Promise<void>
  logOut: () => Promise<void>
  forgotPasswordOTP: (email: string) => Promise<void>
  staffForgot: any
  emailStaff: string
  verifyOTP: (email: string, code: string) => Promise<void>
  sendEmailOTP: (id: string | undefined) => Promise<VerifyEmailOtp>
  // emailEditStaff: string
  sendEmailOTPUser: (id: string | undefined) => Promise<VerifyEmailOtp>
  // emailEditUser: string
  getStaffDetail: (id: string | undefined) => Promise<any>
  getStudentDetail: (id: string | undefined) => Promise<any>
  getProfile: () => any
  profileMe: IStudentDetail | null | undefined
  makeUseContactDefault: ({
    userId,
    userContactId,
  }: {
    userId: string
    userContactId: string
  }) => Promise<void>
}

// initContext
const initContext: Context = {
  logOut: async () => {},
  loginUsername: async () => {},
  forgotPasswordOTP: async () => {},
  staffForgot: null,
  emailStaff: '',
  verifyOTP: async () => {},
  sendEmailOTP: async () => {
    return { success: false, data: false }
  },
  // emailEditStaff: '',
  sendEmailOTPUser: async () => {
    return { success: false, data: false }
  },
  // emailEditUser: '',
  getStaffDetail: async () => {},
  getStudentDetail: async () => {},
  getProfile: async () => {},
  profileMe: null,
  makeUseContactDefault: async () => {},
}

const UserContext = createContext<Context>(initContext)

export function UserProvider(props: PropsWithChildren<{}>) {
  const [emailStaff, setEmailStaff] = useState('')
  const [staffForgot, setStaffForgot] = useState<IForgotPassword>()
  // const [emailEditStaff, setEmailEdit] = useState('')
  // const [emailEditUser, setEmailEditUser] = useState('')
  const [profileMe, setProfileMe] = useState<IStudentDetail>()

  // login username
  const loginUsername = async (username: string, password: string) => {
    const res = await AuthAPI.loginByUsername({ username, password })
    if (!res) return
    const userInfo = res?.data?.tokens
    if (userInfo) {
      UsersAPI.myprofile().then((res) => setProfileMe(res))
    }
    setActToken(userInfo?.act)
    setRefreshToken(userInfo?.rft)
  }

  // logOut
  const logOut = async () => {
    await AuthAPI.logout().then(() => localStorage.clear())
  }

  const forgotPasswordOTP = async (email: string) => {
    await StaffAPI.forgotPassword(email).then(() => setEmailStaff(email))
  }

  const verifyOTP = async (email: string, code: string) => {
    await StaffAPI.verifyEmailOTP(email, code).then((res) => setStaffForgot(res?.data))
  }

  const sendEmailOTP = async (id: string | undefined) => {
    const res = await StaffAPI.sendOTP(id)
    return res
  }

  const sendEmailOTPUser = async (id: string | undefined) => {
    const res = await UsersAPI.sendOTP(id)
    return res
  }

  const getStaffDetail = async (id: string | undefined) => {
    const res = await StaffAPI.detail(id)
    return res
  }

  const getStudentDetail = async (id: string | undefined) => {
    const res = await UsersAPI.detail(id)
    return res
  }

  const getProfile = async () => {
    const res = await UsersAPI.myprofile()
    return res
  }

  const makeUseContactDefault = async ({
    userId,
    userContactId,
  }: {
    userId: string
    userContactId: string
  }) => {
    try {
      await UsersAPI.makeUseContactDefault({ userId, userContactId })
    } catch (error: any) {
      const { code, message } = error?.response?.data?.error
      if (code && code === ErrorCode.HUBSPOT_CONTACT_EMAIL_INVALID) {
        toast.error(message)
      }
    }
  }

  useEffect(() => {
    if (!isEmpty(getActToken())) {
      UsersAPI.myprofile().then((res) => setProfileMe(res))
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        loginUsername,
        logOut,
        forgotPasswordOTP,
        staffForgot,
        emailStaff,
        verifyOTP,
        sendEmailOTP,
        sendEmailOTPUser,
        getStaffDetail,
        getStudentDetail,
        getProfile,
        profileMe,
        makeUseContactDefault,
      }}
      {...props}
    />
  )
}

export function useUserContext(): Context {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('Error!')
  }

  return context
}
