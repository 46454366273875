import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DepartmentAPI } from 'src/apis/department'
import DetailHeader from 'src/common/DetailHeader'
import { departmentKeys, DETAIL_TAB_TITLE, PageLink } from 'src/constants'
import { DepartmentProvider } from 'src/context/DepartmentContext'
import { ITabs } from 'src/type'
import DepartmentOverview from './DepartmentOverview'
import DepartmentSetting from './DepartmentSetting'

const { OVERVIEW, SETTING } = DETAIL_TAB_TITLE

const DepartmentTabs = () => {
  const { pathname } = useLocation()
  const { id } = useParams<{ id: string }>()

  // Get the current tab from the URL
  const currentTab = pathname.split(`/${id}/`)[1] as DETAIL_TAB_TITLE // Cast to enum type

  switch (currentTab) {
    case DETAIL_TAB_TITLE.OVERVIEW:
      return <DepartmentOverview />
    case DETAIL_TAB_TITLE.SETTING:
      return <DepartmentSetting />
    default:
      return <DepartmentOverview />
  }
}

const DepartmentDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const detailIdPath = `${PageLink.DEPARTMENTS}/${id}`

  // Config Tabs
  const tabs: ITabs[] = [
    {
      link: `${detailIdPath}/${OVERVIEW}`,
      title: 'Overview',
    },
    {
      link: `${detailIdPath}/${SETTING}`,
      title: 'Settings',
    },
  ]

  const fetchDepartment = async () => {
    if (!id) {
      throw new Error('Exam ID is undefined')
    }
    const response = await DepartmentAPI.getDetail(id!)
    return response.data
  }

  const { data, isLoading, isSuccess } = useQuery({
    queryKey: id && departmentKeys.detail(id),
    queryFn: fetchDepartment,
    enabled: !!id,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (id && pathname === `${detailIdPath}`) {
      navigate(`${detailIdPath}/${OVERVIEW}`)
    }
  }, [id, pathname])

  return (
    <DepartmentProvider
      value={{
        data: data || null || undefined,
        isLoading: isLoading,
        id: id!,
      }}
    >
      <div>
        <DetailHeader title='Department Detail' tabs={tabs} loading={isLoading} />
        <div className='card'>{isSuccess && <DepartmentTabs />}</div>
      </div>
    </DepartmentProvider>
  )
}

export default DepartmentDetail
