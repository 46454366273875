import { fetcher } from 'src/services/request'
import { IEventTestRes, IResponse } from 'src/type'
import { IAddParticipantError } from 'src/type/entrancetest'

export class EventTestAPI {
  static createEventTest(data: any): Promise<any> {
    return fetcher('event-tests', {
      method: 'POST',
      data: data,
    })
  }

  static getEventTest(page_index: number, page_size: number, params?: Object): Promise<any> {
    return fetcher(`event-tests?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static editEventTest(id: string | undefined, data: any): Promise<any> {
    return fetcher(`event-tests/${id}`, {
      method: 'PUT',
      data: data,
    })
  }

  static getDetail(id: string | undefined): Promise<any> {
    return fetcher(`event-tests/${id}`)
  }

  static getParticipants({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`event-tests/participants?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getExportParticipants({
    id,
    page_index,
    page_size,
    params,
  }: {
    id: string
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(
      `event-tests/${id}/export/test-participants?page_index=${page_index}&page_size=${page_size}`,
      {
        params: params,
      }
    )
  }

  static deleteParticipant(id: string, data: { user_ids: string[] }): Promise<any> {
    return fetcher(`event-tests/${id}/participants`, {
      method: 'DELETE',
      data: data,
    })
  }

  static getParticipantEventTest({
    id,
    page_index,
    page_size,
    params,
  }: {
    id?: string | undefined
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(
      `event-tests/${id}/participants?page_index=${page_index}&page_size=${page_size}`,
      {
        params: params,
      }
    )
  }

  static addParticipantToEventTest(
    id: string,
    data: { user_ids: string[] }
  ): Promise<IResponse<IAddParticipantError>> {
    return fetcher(`event-tests/${id}/participants`, {
      method: 'POST',
      data,
    })
  }

  static getAnswer(
    id: string | undefined,
    page_index: number,
    page_size: number,
    params?: Object
  ): Promise<IEventTestRes> {
    return fetcher(
      `event-tests/attempts/answers/${id}?page_index=${page_index}&page_size=${page_size}`,
      {
        params: params,
      }
    )
  }
}
