import { UseFormReturn } from 'react-hook-form'
import { Select } from 'antd'
import { SHOP_PRODUCT_STATUS } from 'src/constants'
import { BUTTON_TEXT } from 'src/constants/lang'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import MenuTabsSection from 'src/pages/shop/blogs/MenuTabs'
import Advanced from 'src/pages/shop/blogs/Advanced'
import { useEffect } from 'react'
import { IUseShopFilterResult } from 'src/hooks/use-shop-filter'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { Dropdown } from 'react-bootstrap'
import { IStepProgress } from 'src/type'

const { Option } = Select

export const ACTION_STATUS = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
]

interface IProps extends IStepProgress {
  handleSubmit: (e: any) => void
  useFormProp: UseFormReturn<any>
  onCancel?: () => void
  loading?: boolean
  categoryFilter: IUseShopFilterResult
}

const CreateAndUpdateSetting = ({
  categoryFilter,
  useFormProp,
  handleSubmit,
  onCancel,
  step,
  setStep,
}: IProps) => {
  const { control, getValues } = useFormProp
  const {
    dataList: categoryList,
    getData: getCategories,
    debounceGetData: debounceGetCategories,
    handleNextPage,
  } = categoryFilter

  useEffect(() => {
    debounceGetCategories()
  }, [])

  return (
    <>
      <div className='form d-flex flex-column flex-lg-row'>
        <Processbar
          step={step}
          setNewStep={setStep}
          showCancel
          showButtonPrimary={false}
          onClick={handleSubmit}
          onCancel={onCancel}
          canToggle={true}
        >
          <Dropdown.Item>Preview</Dropdown.Item>
          <Dropdown.Item onClick={handleSubmit}>{BUTTON_TEXT.SAVE}</Dropdown.Item>
        </Processbar>
        <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-8'>
          <div className='card card-flush p-8'>
            <SappLabel label='Status' required isLabel />
            <div>
              <HookFormSelectAntd
                size='large'
                name='status'
                control={control}
                placeholder='Status'
                filterOption={true}
                defaultValue={getValues('status')}
                className='sapp-h-45px fs-6 sapp-field_question-select'
                options={SHOP_PRODUCT_STATUS}
              />
            </div>
          </div>
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='tab-content'>
            <div className='tab-pane fade show active'>
              <div className='d-flex flex-column gap-7 gap-lg-10'>
                <div className='card card-flush pb-4 pt-8'>
                  <div className='card-body pt-0'>
                    <div className='text-gray-800 fw-bold sapp-mb-32px sapp-fs-20px'>Settings</div>
                    <div className='mb-10 fv-row'>
                      <HookFormSelectMultiple
                        loading={categoryList.loading}
                        name='product_category_ids'
                        label='Categories'
                        control={control}
                        options={categoryList.data.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        handleNextPage={handleNextPage}
                        onSearch={(e: any) => {
                          if (e === undefined) {
                            return
                          }
                          debounceGetCategories(e)
                        }}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormSelectAntd
                        name='primary_product_category_id'
                        placeholder='None'
                        control={control}
                        size='large'
                        label='Primary Category'
                        showSearch
                        handleNextPage={handleNextPage}
                        onSearch={async (e) => {
                          debounceGetCategories(e)
                          return
                        }}
                        required
                        onFocus={async () => {
                          if (categoryList?.data?.length <= 0) {
                            await getCategories()
                            return
                          }
                        }}
                        onChange={async (e: any) => {
                          if (e === undefined) {
                            debounceGetCategories()
                          }
                        }}
                        loading={categoryList.loading}
                        allowClear
                        classNameHeight='sapp-h-45px'
                        options={categoryList.data?.map((e) => {
                          return { label: e.name, value: e.id }
                        })}
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        label='URL'
                        name='suffix_url'
                        groupText='sapp.shop.edu.vn/sapp-academy/'
                        className='sapp-h-45px'
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        label='Meta Title'
                        name='meta_title'
                        className='sapp-h-45px'
                        required
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormEditor
                        height={380}
                        name='meta_description'
                        control={control}
                        math={true}
                        label='Meta Description'
                        defaultValue={getValues('meta_description')}
                        resourceLocation={RESOURCE_LOCATION.MEDIA}
                        object_id={undefined}
                        required
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        label='Focus Keyword'
                        name='focus_keyword'
                        className='sapp-h-45px'
                      />
                    </div>

                    <div className='mb-10 fv-row'>
                      <MenuTabsSection
                        typeofSection={'Advanced'}
                        SectionName={'Advanced'}
                        tabs={[
                          {
                            id: 'tab1',
                            title: 'Advanced',
                            content: <Advanced useForm={useFormProp} />,
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateAndUpdateSetting
