import { Select, TablePaginationConfig } from 'antd'
import { debounce } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { RolesAPI } from 'src/apis/roles'
import { StaffAPI } from 'src/apis/staffs'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { FILTER_SELECTALL_SORTBY, GENDER, PageLink, STATUS } from 'src/constants'
import { BUTTON_TEXT } from 'src/constants/lang'
import { ISelect } from 'src/type/common'
import { IRoleRes } from 'src/type/staffs.'
import { formatDate, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import HookFormDateTime from '../base/datetime/HookFormDateTime'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import HookFormTextField from '../base/textfield/HookFormTextField'
import ListFilterLayout from '../layout/listFilter'
import UserListGrouping from '../user-management/UserListGrouping'
import { ListViewProvider } from './components/core/ListViewProvider'
import { QueryRequestProvider, useQueryRequest } from './components/core/QueryRequestProvider'
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseDataPagination,
} from './components/core/QueryResponseProvider'
import { StaffListToolbar } from './components/header/StaffListToolbar'
import { StaffTable } from './components/table/StaffTable'

const { Option } = Select

const fieldNames = ['text', 'status', 'role', 'sortType', 'fromDate', 'toDate']
const initialValues: any = {
  role: '',
  text: '',
  status: '',
  sortType: '',
  fromDate: '',
  toDate: '',
}

const StaffList = () => {
  const [openBlocked, setOpenBlocked] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const response = useQueryResponseDataPagination()
  const [roles, setRoles] = useState<IRoleRes>()
  const { updateState } = useQueryRequest()
  const { refetch, isLoading } = useQueryResponse()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Map<number, React.Key[]>>(new Map())
  const selectedRowId = useMemo(() => [...selectedRowKeys.values()].flat(), [selectedRowKeys])
  const { control, getValues, reset, setValue, watch } = useForm({
    mode: 'onSubmit',
  })
  const rolesNew = roles?.roles?.map((role: { name: string; code: string }) => ({
    label: role?.name,
    value: role?.code,
  }))
  const queryParams = {
    page_index: Number(searchParams.get('page_index')) ?? 1,
    page_size: Number(searchParams.get('page_size')) ?? 10,
    textSearch: searchParams.get('text') ?? '',
    sortRole: searchParams.get('role') ?? '',
    sortStatus: searchParams.get('status') ?? '',
    sortType: searchParams.get('sortType') ?? '',
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    sex: searchParams.get('sex') ?? '',
  }

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: queryParams?.page_index || 1,
    pageSize: queryParams?.page_size || 10,
    total: 10,
    showSizeChanger: true,
    showQuickJumper: true,
  })

  const handleReset = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    navigate(PageLink.STAFFS)
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage || 1,
      page_size: size || 10,
      text: getValues('text'),
      role: replaceValueAll(getValues('role')),
      status: replaceValueAll(getValues('status')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
      sex: replaceValueAll(getValues('sex')) ?? '',
    }
    updateState(queryParam)
    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    updateState({
      page_index: 1,
      page_size: 10,
      text: getValues('text'),
      status: replaceValueAll(getValues('status')),
      sortType: replaceValueAll(getValues('sortType')),
      role_code: replaceValueAll(getValues('role')),
      fromDate: getValues('fromDate')
        ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
        : '',
      toDate: getValues('toDate')
        ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
        : '',
      dateField: 'updated_at',
      sex: replaceValueAll(getValues('sex')),
    })
    refetch()
    handleChangeParams(1, queryParams.page_size || 10)
  }

  const handleResetAll = () => {
    handleReset()
    updateState({
      page_index: 1,
      page_size: 10,
      text: '',
      status: '',
      sortType: '',
      role_code: '',
      fromDate: null,
      toDate: null,
      dateField: '',
      sex: '',
    })
  }

  const blockUser = useMutation(() => StaffAPI.blocked(selectedRowId as string[]), {
    onSuccess: () => {
      refetch()
      setSelectedRowKeys(new Map())
      toast.success('Block Successfully!')
      cancel(true)
      setOpenBlocked(false)
    },
  })

  const fetchRoles = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await RolesAPI.get(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const handlNextPageRole = async (params: Object) => {
    const totalPages = roles?.metadata?.total_pages
    const pageIndex = roles?.metadata?.page_index as number
    const pageSize = roles?.metadata?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res: any = await fetchRoles(pageIndex + 1, pageSize, params)
      const results = roles?.roles?.concat(res?.data?.roles)
      setRoles({
        metadata: res?.data?.metadata,
        roles: results,
      })
    }
  }

  const getRoles = async ({ params }: any) => {
    const res: any = await fetchRoles(1, 20, params)
    setRoles(res?.data)
  }

  const debounceSearchMentor = debounce((e) => {
    getRoles({ params: { name: e } })
  }, 500)

  return (
    <>
      <div className='px-10 border-0 pt-10'>
        <div className=''>
          <ListFilterLayout>
            <div className='card-title justify-content-center mb-0 mx-0 mt-0'>
              <HookFormTextField
                control={control}
                name='text'
                placeholder='Search'
                defaultValue={queryParams.textSearch ?? ''}
                isListScreen
                onSubmit={onSubmit}
              />
            </div>

            <HookFormSelectAntd
              control={control}
              name='status'
              placeholder='Status'
              defaultValue={queryParams.sortStatus ?? ''}
              classNameHeight='sapp-h-40'
              options={STATUS}
            />

            <HookFormSelectAntd
              control={control}
              name='role'
              placeholder='Role'
              showSearch
              defaultValue={queryParams.sortRole ?? ''}
              classNameHeight='sapp-h-40'
              onSearch={(e: any) => {
                if (e === undefined) {
                  return
                }
                debounceSearchMentor(e)
              }}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                debounceSearchMentor(e?.target?.value)
              }}
              handleNextPage={(e: any) => handlNextPageRole({ params: { name: e } })}
              options={rolesNew ?? []}
            />

            <HookFormSelectAntd
              control={control}
              name='sortType'
              placeholder='Sort by'
              showSearch
              defaultValue={queryParams.sortType ?? ''}
              classNameHeight='sapp-h-40'
              options={FILTER_SELECTALL_SORTBY}
            />

            <HookFormSelectAntd
              control={control}
              name='sex'
              placeholder='Gender'
              defaultValue={queryParams.sex ?? ''}
              classNameHeight='sapp-h-40'
              options={GENDER}
            />

            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams.fromDate ?? ''}
              isListScreen
            />

            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams.toDate ?? ''}
              isListScreen
            />
          </ListFilterLayout>
        </div>
      </div>
      <div className='card-header border-0 pt-6'>
        <div className='d-flex'>
          <SAPPFIlterButton
            titleReset='Reset'
            titleSubmit={BUTTON_TEXT.SEARCH}
            okClick={onSubmit}
            resetClick={handleResetAll}
            disabled={isLoading}
            loading={isLoading}
          />
        </div>
        <>
          {/* start:: button open modal */}
          {selectedRowId.length > 0 ? (
            <div className='col-xl-8 col-lg-9 col-sm-8 px-xl-3 pe-xl-0'>
              <UserListGrouping
                selected={selectedRowId}
                blockUser={async () => await blockUser.mutateAsync()}
                openBlocked={openBlocked}
                setOpenBlocked={setOpenBlocked}
              />
            </div>
          ) : (
            <div className='col-xl-8 col-lg-9 col-sm-8 px-xl-3'>
              <StaffListToolbar
                valueStatus={watch('status')}
                valueSortBy={watch('sortType')}
                searchTerm={watch('text')}
                sortRole={watch('role')}
                fromDate={watch('fromDate')}
                toDate={watch('toDate')}
                sex={watch('sex')}
                location={location}
              />
            </div>
          )}
          {/* end:: button open modal */}
        </>
      </div>

      <StaffTable
        pagination={pagination}
        setPagination={setPagination}
        staffList={response?.data}
        handleChangeParams={handleChangeParams}
        queryParams={queryParams}
        setSelected={setSelectedRowKeys}
        selected={selectedRowKeys}
      />
    </>
  )
}

const StaffListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <StaffList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { StaffListWrapper }
