import { Skeleton } from 'antd'
import { KTIcon } from 'src/_metronic/helpers'
import ClassStatusCell from 'src/components/classes/profile/ClassStatusCell'
import SappTabs from 'src/components/tabs/SappTabs'
import { ITabs, ROOM_MODE } from 'src/type'
import { IRoom } from 'src/type/area'

interface IProps {
  roomDetail: IRoom | undefined
  tabs: ITabs[]
  loading: boolean
}

const RoomCard = ({ roomDetail, tabs, loading }: IProps) => {
  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='pt-10 px-10 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start mb-5'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-5'>
                  {loading ? (
                    <Skeleton.Button active block />
                  ) : (
                    <div className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                      {roomDetail?.name}
                    </div>
                  )}
                </div>
                <div className='d-flex align-items-center gap-5'>
                  <div>
                    {loading ? (
                      <Skeleton.Button active block size='small' />
                    ) : (
                      <ClassStatusCell status={roomDetail?.status?.toLocaleLowerCase()} />
                    )}
                  </div>
                  <div className='d-flex align-items-center column-gap-1'>
                    {roomDetail?.mode === ROOM_MODE.PHYSICAL && (
                      <KTIcon iconName='geolocation' className='fs-1' />
                    )}
                    <div className='fs-6 text-gray-700'>{roomDetail?.facility?.name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? <Skeleton.Button active block /> : <SappTabs tabs={tabs} />}
      </div>
    </div>
  )
}

export default RoomCard
