import { zodResolver } from '@hookform/resolvers/zod'
import { Col, Row } from 'antd'
import clsx from 'clsx'
import { capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormSelectMultiple from 'src/components/base/select/HookFormSelectMultiple'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import { DETAIL_TAB_TITLE, PageLink } from 'src/constants'
import { getSelectOptions } from 'src/helper/getSelectOptions'
import { useConfirm } from 'src/hooks/use-confirm'
import useDepartmentParents from 'src/hooks/useDepartmentParent'
import { FormType, IDepartmentForm } from 'src/type'
import { BUSINESS_UNITS, DEPARTMENT_TYPES } from 'src/type/department/enum'
import { schema } from './schema'
import styles from './styles.module.scss'

interface IProps {
  data?: IDepartmentForm
  drawerOpen?: boolean
  setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>
  submit: (...args: any[]) => any
  formType: FormType
}

const MAX_COUNT = 2

/**
 * Represents a form component for creating or editing a department.
 *
 * @interface IProps
 * @property {IDepartmentForm} [data] - The initial data to populate the form (optional).
 * @property {boolean} [drawerOpen] - Controls whether the drawer containing the form is open.
 * @property {React.Dispatch<React.SetStateAction<boolean>>} [setDrawerOpen] - Function to toggle the drawer state (optional).
 * @property {(...args: any[]) => any} submit - The function to handle form submission.
 * @property {FormType} formType - Specifies whether the form is for creating or editing a department.
 */
const DepartmentForm = ({ data, submit, drawerOpen, setDrawerOpen, formType }: IProps) => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const { confirm, contextHolder } = useConfirm()
  const { OVERVIEW } = DETAIL_TAB_TITLE

  const [type, setType] = useState<DEPARTMENT_TYPES | undefined>(data?.type || undefined)
  const [units, setUnits] = useState<{ name: string; value: string }[]>([
    {
      name: BUSINESS_UNITS.HO,
      value: BUSINESS_UNITS.HO,
    },
  ])

  const { parents, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage, refetch } =
    useDepartmentParents(type || DEPARTMENT_TYPES.BOARD, id)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm<IDepartmentForm>({
    resolver: zodResolver(schema(type)),
    defaultValues: data ? data : { unit: [BUSINESS_UNITS.HO] },
  })

  const departments = Object.values(DEPARTMENT_TYPES).map((type) => ({
    name: capitalize(type.replaceAll('_', ' ')),
    value: type,
  }))

  const onSubmitHandler: SubmitHandler<IDepartmentForm> = (formData) => {
    const formattedData = {
      ...formData,
      unit: formData?.unit?.filter((item) => item !== BUSINESS_UNITS.HO)[0],
      parent_id: formData.parent_id === '' ? undefined : formData.parent_id,
    }
    submit(formattedData)
  }

  const handleCancel = () => {
    const commonConfirmOptions = {
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
    }

    const actions: Record<FormType, () => void> = {
      [FormType.EDIT]: () => navigate(`${PageLink.DEPARTMENTS}/${id}/${OVERVIEW}`),
      [FormType.CREATE]: () => {
        reset()
        setDrawerOpen!(false)
      },
    }

    if (isDirty) {
      const message = {
        [FormType.EDIT]: 'Bạn có chắc chắn muốn hủy các thay đổi chưa lưu không?',
        [FormType.CREATE]: 'Bạn có chắc chắn muốn huỷ department đang tạo không?',
      }

      confirm({
        ...commonConfirmOptions,
        body: [message[formType] || 'Bạn có chắc chắn muốn hủy các thay đổi chưa lưu không?'],
        onClick: actions[formType],
      })
    } else {
      actions[formType]()
    }
  }

  useEffect(() => {
    setValue('unit', [BUSINESS_UNITS.HO])
    if (type === DEPARTMENT_TYPES.DEPARTMENT) {
      setUnits(
        Object.values(BUSINESS_UNITS).map((unit) => ({
          name: unit.replace('_', ' '),
          value: unit,
        }))
      )
    } else {
      setUnits([
        {
          name: BUSINESS_UNITS.HO,
          value: BUSINESS_UNITS.HO,
        },
      ])
    }
    if (type === DEPARTMENT_TYPES.BOARD) {
      setValue('parent_id', '')
    }
  }, [type])

  useEffect(() => {
    reset()
  }, [drawerOpen])

  return (
    <>
      {contextHolder}
      <div className={clsx(['row gy-10'])}>
        <HookFormTextField control={control} name='name' label='Department Name' required />
        <HookFormTextField control={control} name='short_name' label='Department Short Name' />
        <Row className='w-100' gutter={[24, 24]}>
          <Col sm={24} xl={8}>
            <HookFormSelectAntd
              control={control}
              name='type'
              label='Department Level'
              required
              onChange={(val) => {
                setType(val as DEPARTMENT_TYPES)
                setValue('parent_id', '')
              }}
              options={departments.map((department) => ({
                label: department.name,
                value: department.value,
              }))}
            />
          </Col>
          <Col sm={24} xl={8}>
            <HookFormSelectAntd
              control={control}
              name='parent_id'
              label='Department Parent'
              required={type !== DEPARTMENT_TYPES.BOARD}
              loading={isLoading || isFetchingNextPage}
              disabled={type === DEPARTMENT_TYPES.BOARD}
              handleNextPage={() => hasNextPage && fetchNextPage()}
              onDropdownVisibleChange={(open) => open && refetch()}
              options={getSelectOptions(
                parents.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                })),
                data?.type === type
                  ? {
                      value: data?.parent_id,
                      label: data?.parent_name,
                    }
                  : {}
              )}
            />
          </Col>
          <Col sm={24} xl={8}>
            <HookFormSelectMultiple
              control={control}
              name='unit'
              label='Unit'
              required
              loading={false}
              maxCount={MAX_COUNT}
              onSearch={() => {}}
              options={[
                ...units.map((unit) => {
                  return {
                    label: unit.name,
                    value: unit.value,
                    disabled: unit.value === BUSINESS_UNITS.HO,
                  }
                }),
              ]}
            />
          </Col>
        </Row>
        <div>
          <HookFormEditor
            height={350}
            name='description'
            control={control}
            resourceLocation={RESOURCE_LOCATION.DEPARTMENT}
            label='Description'
            object_id={undefined}
            defaultValue={data?.description}
          />
        </div>
      </div>
      {/* start:: footer */}
      <div className={clsx({ [styles.createDrawerFooter]: !id }, styles.formFooter)}>
        <SAPPDialogButtonsCancelSubmit
          cancelClick={handleCancel}
          cancelButtonCaption={'Cancel'}
          okButtonCaption={'Submit'}
          okOnClick={() => {
            const onSubmit = handleSubmit(onSubmitHandler)
            if (formType === FormType.EDIT) {
              confirm({
                okButtonCaption: 'Yes',
                cancelButtonCaption: 'No',
                body: ['Bạn có chắc chắn muốn sửa không?'],
                onClick: onSubmit,
              })
            }
            if (formType === FormType.CREATE) {
              onSubmit()
            }
          }}
          className='justify-content-end d-flex m-0'
        />
      </div>
      {/* end:: footer */}
    </>
  )
}

export default DepartmentForm
