import { divide, round } from 'lodash'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import withAuthComponents from 'src/components/auth/with-auth-components'
import Card from 'src/components/base/Card'
import PageLayouts from 'src/components/layout/PageLayouts'
import RenderStudentProfile from 'src/components/student-profile/RenderStudentProfile'
import { MY_PROFILE, PageLink, STUDENT_PROFILE } from 'src/constants'
import { userKeys } from 'src/constants/queryKeyFactory'
import { StudentProvider } from 'src/context/StudentContext'
import { ITabs } from 'src/type'
import { IStudentDetail } from 'src/type/students'

const StudentProfile = () => {
  const { id } = useParams()
  const breadcrumbs: ITabs[] = [
    {
      link: `${STUDENT_PROFILE}/${id}/overview`,
      title: 'LMS',
    },
    {
      link: `${PageLink.STUDENTS}`,
      title: 'Students List ',
    },
    {
      link: `${PageLink.AUTH_LOGIN}`,
      title: 'Student Profile',
    },
  ]

  // Config Tabs
  const tabs: ITabs[] = [
    {
      link: `${STUDENT_PROFILE}/${id}/overview`,
      title: 'Overview',
    },
    {
      link: `${STUDENT_PROFILE}/${id}/setting`,
      title: 'Setting',
    },
    {
      link: `${STUDENT_PROFILE}/${id}/class`,
      title: 'Class & Course',
    },
    {
      link: `${STUDENT_PROFILE}/${id}/${MY_PROFILE.CERTIFICATE}`,
      title: 'Certificate',
    },
    {
      link: `${STUDENT_PROFILE}/${id}/security`,
      title: 'Security',
    },
  ]

  const getStudentDetail = async (id: string) => {
    const res = await UsersAPI.detail(id)
    return res.data
  }

  const { data: studentDetail, isLoading } = useQuery<IStudentDetail>({
    queryKey: userKeys.studentDetail(id!),
    queryFn: () => getStudentDetail(id!),
    enabled: !!id,
  })

  const inputFields = [
    studentDetail?.key,
    studentDetail?.detail?.full_name,
    studentDetail?.username,
    studentDetail?.hubspot_contact_id,
    studentDetail?.detail?.email,
    studentDetail?.detail?.phone,
    studentDetail?.status,
    studentDetail?.key,
    studentDetail?.detail?.sex,
    studentDetail?.detail?.university,
    studentDetail?.detail?.major,
    studentDetail?.detail?.learning_purpose,
    studentDetail?.detail?.contact_detail,
    studentDetail?.detail?.special_note,
    studentDetail?.detail?.classification,
    studentDetail?.detail?.note,
    studentDetail?.files?.[0]?.file_url,
    studentDetail?.files?.[1]?.file_url,
    studentDetail?.files?.[2]?.file_url,
  ]

  const countNonEmptyFields = () => {
    return inputFields.filter((input) => input !== '' && input !== null && input !== undefined)
      .length
  }

  return (
    <StudentProvider
      value={{
        studentDetail: studentDetail || null || undefined,
        isLoading: isLoading,
      }}
    >
      <PageLayouts pageTitle='Student Profile' breadcrumbs={breadcrumbs}>
        <Card
          userDetail={studentDetail}
          tabs={tabs}
          loading={isLoading}
          progress={round(divide(countNonEmptyFields(), inputFields.length), 2)}
        />
        <RenderStudentProfile />
      </PageLayouts>
    </StudentProvider>
  )
}

export default withAuthComponents(StudentProfile)
