import clsx from 'clsx'
import { ReactNode, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './ButtonIcon.module.scss'

interface IProps {
  title?: string
  children: ReactNode
  onClick?: (e?: any) => void
  className?: string
  trigger?: 'click'
  placement?: 'bottom-end'
  toggle?: 'modal'
  target?: string
  customButton?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  link?: string | undefined
  loading?: boolean
  disabled?: boolean
  classTitle?: string
  isListScreen?: boolean
  isHoverCustom?: boolean
}

const ButtonIcon = ({
  title,
  children,
  onClick,
  className = '',
  trigger,
  placement,
  target,
  toggle,
  customButton,
  type,
  link,
  loading,
  disabled,
  classTitle,
  isListScreen,
  isHoverCustom = true,
}: IProps) => {
  if (link) {
    return (
      <Link
        to={link}
        className={`${className} btn ${customButton || 'btn-primary'} ${
          isListScreen ? 'sapp-h-40' : 'sapp-h-45px'
        } ${isHoverCustom ? 'btn-icon--custom' : ''}`}
      >
        {children}
        {title}
      </Link>
    )
  }
  return (
    <button
      className={clsx(
        className,
        'btn',
        customButton || 'btn-primary',
        isListScreen ? 'sapp-h-40' : 'sapp-h-45px',
        { 'btn-icon--custom': isHoverCustom },
        disabled && styles.disabled
      )}
      onClick={onClick}
      data-kt-menu-trigger={trigger}
      data-kt-menu-placement={placement}
      data-bs-toggle={toggle}
      data-bs-target={target}
      type={type}
      disabled={loading || disabled}
    >
      {loading ? (
        <span className='indicator-progress d-block'>
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      ) : (
        <>
          {children}
          <span className='indicator-label lh-1 align-middle'>
            {classTitle ? <div className={classTitle}>{title}</div> : title}
          </span>{' '}
        </>
      )}
    </button>
  )
}

export default ButtonIcon
