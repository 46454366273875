import { format } from 'date-fns'
import _ from 'lodash'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import HeaderTab from 'src/components/base/HeaderTab'
import ItemProfile from 'src/components/base/ItemProfile'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import { FORMAT_DATETIME, MY_PROFILE, TEACHER_PROFILE } from 'src/constants'
import { programOrder, sectionOrder, subjectOrder } from 'src/constants/classes'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import { TEACHING_STATUS } from 'src/constants/users/teacher'
import { useUserContext } from 'src/context/UserProvider'
import { groupByOrder, processFields } from 'src/helper/teacherHelper'
import { Role } from 'src/type'
import { PROGRAM } from 'src/type/courses'
import { IStudentDetail } from 'src/type/students'
import { TeachableInstance } from 'src/type/teacher'
import { formatDate } from 'src/utils'

interface IProps {
  teacherDetail: IStudentDetail | undefined
  loading: boolean
  setTeacherDetail: Dispatch<SetStateAction<any>>
}

const TeacherProfileDetail = ({ teacherDetail, loading, setTeacherDetail }: IProps) => {
  const { id } = useParams()
  const { getStudentDetail } = useUserContext()

  const getBadgeColor = (item: any) => {
    switch (item.toUpperCase()) {
      case PROGRAM.CFA:
        return 'cfa'
      case PROGRAM.ACCA:
        return 'acca'
      case PROGRAM.CMA:
        return 'cma'
      case 'CERT/DIP':
        return 'primary'

      default:
        return 'light'
    }
  }

  const userContact = teacherDetail?.user_contacts?.find((user) => user.is_default)
  const { profileMe } = useUserContext()
  const allowRender = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.EDIT_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  //#region Handle extracting program, subject and section
  const teachableInstances = teacherDetail
    ? (teacherDetail?.teacher_teachable_instances_mapping as [])
    : []
  const userStaffInstances = teacherDetail ? (teacherDetail?.user_staff_instances as []) : []
  const program = processFields(teachableInstances, 'category', 'name')
  const personInCharge = processFields(userStaffInstances, 'staff', 'username')

  const subject: {
    subject: string
    program: string
    color: string
  }[] = _.uniq(
    teachableInstances?.flatMap((item: TeachableInstance) => {
      return {
        program: item.category.name,
        subject: item.subject.code,
        color: getBadgeColor(item.category.name),
      }
    })
  )
  const section: {
    code: string
    program: string
  }[] = _.uniq(
    teachableInstances?.flatMap((item: TeachableInstance) =>
      item?.feature_section_instances?.map((instance) => {
        return {
          code: instance?.code,
          program: item.category.name,
        }
      })
    )
  )

  const groupedSubjects = groupByOrder(subject, programOrder, 'program')
  const groupedSections = groupByOrder(section, programOrder, 'program')
  //#endregion

  useEffect(() => {
    if (!id || loading) return

    getStudentDetail(id).then((res) => setTeacherDetail(res?.data))
  }, [id])

  return (
    <>
      {loading ? (
        <LoadingProfileDetail />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab
            title='Profile Details'
            titleButton={allowRender ? 'Edit Profile' : ''}
            link={`${TEACHER_PROFILE}/${id}/${MY_PROFILE.SETTING}`}
          />

          <div className='card-body p-9'>
            <ItemProfile title='Code' body={teacherDetail?.key} />
            <ItemProfile
              title={LANG_PLACEHOLDER.FULLNAME}
              body={teacherDetail?.detail?.full_name}
            />
            <ItemProfile title='Username' body={teacherDetail?.username} />
            <ItemProfile title='Email' body={teacherDetail?.detail?.email ?? userContact?.email} />
            <ItemProfile
              title='Phone Number'
              body={teacherDetail?.detail?.phone ?? userContact?.phone}
            />
            <ItemProfile title='Status' status={teacherDetail?.status} />
            <ItemProfile
              title='Teaching Status'
              body={
                TEACHING_STATUS[teacherDetail?.teacher_status as keyof typeof TEACHING_STATUS] ||
                '-'
              }
            />
            <ItemProfile
              title='Program'
              body={
                program.length > 0
                  ? program
                      .sort((a, b) => programOrder.indexOf(a) - programOrder.indexOf(b))
                      .map((item) => (
                        <span
                          className={`badge badge-${getBadgeColor(item)} fs-7 my-1 me-1`}
                          key={item}
                        >
                          {item}
                        </span>
                      ))
                  : '-'
              }
            />
            <ItemProfile
              title='Subject'
              body={
                subject.length > 0
                  ? groupedSubjects.map(
                      (group, index) =>
                        group.sort(
                          (a, b) =>
                            subjectOrder.indexOf(a.subject) - subjectOrder.indexOf(b.subject)
                        ).length > 0 && (
                          <div key={programOrder[index]}>
                            {group.map((item) => (
                              <span
                                key={item.subject}
                                className={`badge badge-${item.color} fs-7 my-1 me-1`}
                              >
                                {item.subject.replaceAll('-', ' ')}
                              </span>
                            ))}
                          </div>
                        )
                    )
                  : '-'
              }
            />
            <ItemProfile
              title='Section'
              body={
                section.length > 0
                  ? groupedSections.map(
                      (group, index) =>
                        group.length > 0 && (
                          <div key={programOrder[index]} className=''>
                            {group
                              .sort(
                                (a, b) =>
                                  sectionOrder.indexOf(a.code) - sectionOrder.indexOf(b.code)
                              )

                              .map((item, index) => (
                                <span
                                  key={index}
                                  className={`badge badge-${getBadgeColor(
                                    item.program
                                  )} fs-7 my-1 me-1`}
                                >
                                  {item.code}
                                </span>
                              ))}
                          </div>
                        )
                    )
                  : '-'
              }
            />
            <ItemProfile
              title='Person in charge'
              body={
                personInCharge.length > 0
                  ? personInCharge.map((item) => (
                      <span className={`badge badge-light fs-7 my-1 me-1`} key={item}>
                        {item}
                      </span>
                    ))
                  : '-'
              }
            />
            <ItemProfile
              title='Gender'
              body={
                teacherDetail?.detail?.sex === 'MALE'
                  ? 'Male'
                  : teacherDetail?.detail?.sex === 'FEMALE'
                  ? 'Female'
                  : teacherDetail?.detail?.sex === 'OTHERS'
                  ? 'Others'
                  : '-'
              }
            />
            <ItemProfile
              title='D.O.B'
              body={
                teacherDetail?.detail?.dob
                  ? formatDate(teacherDetail?.detail?.dob, 'dd/MM/yyyy')
                  : ''
              }
            />
            <ItemProfile
              title='Facility'
              body={
                teacherDetail?.teacher_facilities && teacherDetail?.teacher_facilities?.length > 0
                  ? teacherDetail?.teacher_facilities?.map((item) => (
                      <span className={`badge badge-light fs-7 my-1 me-1`} key={item?.id}>
                        {item?.facility?.name}
                      </span>
                    ))
                  : '-'
              }
            />
            <ItemProfile title='Current Company' body={teacherDetail?.detail?.current_company} />
            <ItemProfile title='Job Title' body={teacherDetail?.detail?.job_title} />
            <ItemProfile
              title='File chứng chỉ đi kèm'
              body={
                <UploadMulti
                  fileList={teacherDetail?.attachment_files?.map((item) => item.file) ?? []}
                  setFileList={() => {}}
                  isScreenList={false}
                  resourceLocation={RESOURCE_LOCATION.COURSE_SECTION}
                  label='Resources'
                  disabled={false}
                  isshowUploadButton={false}
                />
              }
            />
            <ItemProfile
              title='Facebook Link'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.facebook ?? '#'}
                >
                  {teacherDetail?.detail?.facebook || '-'}
                </Link>
              }
            />
            <ItemProfile
              title='Youtube Link'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.youtube ?? '#'}
                >
                  {teacherDetail?.detail?.youtube || '-'}
                </Link>
              }
            />
            <ItemProfile
              title='LinkedIn Link'
              body={
                <Link
                  className='text-black text-hover-primary'
                  to={teacherDetail?.detail?.linkedin ?? '#'}
                >
                  {teacherDetail?.detail?.linkedin || '-'}
                </Link>
              }
            />
            <ItemProfile
              title='Describe'
              body={
                <div dangerouslySetInnerHTML={{ __html: teacherDetail?.detail?.describe ?? '' }} />
              }
            />
            <ItemProfile
              title='Updated At'
              body={
                teacherDetail?.updated_at
                  ? format(new Date(teacherDetail?.updated_at), FORMAT_DATETIME)
                  : ''
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export default TeacherProfileDetail
