export enum HUBSPOT_ACCA_RESULT {
  'Take an exam' = 'Take an exam',
  'Prize winner' = 'Prize winner',
  'Pass' = 'Pass',
  'Fail' = 'Fail',
}

export enum HUBSPOT_CFA_RESULT {
  'Chờ thi' = 'Chờ thi',
  'Pass (Top 10%)' = 'Pass (Top 10%)',
  'Pass' = 'Pass',
  'Fail' = 'Fail',
  'Hoãn thi' = 'Hoãn thi',
  'Không xác nhận được kết quả' = 'Không xác nhận được kết quả',
}

export enum HUBSPOT_CMA_RESULT {
  'Pass' = 'Pass',
  'Fail' = 'Fail',
  'Chờ thi' = 'Chờ thi',
  'Không xác nhận được kết quả' = 'Không xác nhận được kết quả',
}

export const hubspotProgramResult = {
  ...HUBSPOT_ACCA_RESULT,
  ...HUBSPOT_CMA_RESULT,
  ...HUBSPOT_CFA_RESULT,
} as const

export type HubspotProgramResult = typeof hubspotProgramResult[keyof typeof hubspotProgramResult]
