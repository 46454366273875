export const PageLink = {
  COURSES: '/courses',
  COURSE_CATEGORY: '/course/category',
  TEST: '/course/test',
  TEST_EDIT: '/course/edit-test',
  CASE_STUDY: '/course/case-study',
  COURSE_INFO_CREATE: '/courses-info/create',
  COURSE_INFO_UPDATE: '/courses-info/update',
  COURSE_SUBJECT: '/course/subject',
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  PROFILE_OVERVIEW: '/profile/overview',
  PROFILE_SETTING: '/profile/setting',
  PROFILE_SECURITY: '/profile/security',
  PROFILE_ACTIVITY: '/profile/activity',
  PROFILE_BILLING: '/profile/billing',
  PROFILE_STATEMENTS: '/profile/statements',
  PROFILE_REFERRALS: '/profile/refferrals',
  PROFILE_API_KEYS: '/profile/api-keys',
  PROFILE_LOGS: '/profile/logs',
  PROFILE_MY_ROLE: '/profile/my-role',

  ENTRANCE_TEST_LIST: '/entrance-test/list-entrance-test',
  ENTRANCE_CREATE_TEST_LIST: '/entrance-test/list-entrance-test/create',
  ENTRANCE_EDIT_TEST_LIST: '/entrance-test/list-entrance-test/edit',
  ENTRANCE_CREATE_TEST_LIST_TOPIC: '/entrance-test/list-entrance-test/create/add-topic',
  PARTICIPANTS_LIST: '/entrance-test/participants-list/',
  ENTRANCE_TEST_RESULT: '/entrance-test/list-entrance-test/result',
  ENTRANCE_TEST_RESULT_SCORE_DETAIL: '/entrance-test/participants-list/result/score-detail',
  ENTRANCE_QUIZ_RESULT_TABLE: '/entrance-test/list-entrance-test/quiz-result/table-quiz',
  ENTRANCE_QUIZ_EXPLANATION: '/test-result',
  ENTRANCE_TEST: '/entrance-test',

  SUPPORT_CENTER: '/support-center',
  SUPPORT_OVERVIEW: '/support-center/overview',
  SUPPORT_TICKETS: '/support-center/tickets',
  SUPPORT_FAQS: '/support-center/faqs',
  STAFFS: '/staffs',
  CREATE_STAFF: '/staffs/create',
  UPDATE_STAFF: '/staffs/update',
  STUDENTS: '/students',
  CREATE_STUDENTS: '/students/create',
  UPDATE_STUDENT: '/students/update',
  ROLES: '/roles',
  ADD_ROLES: '/roles/create',
  UPDATE_ROLES: '/roles/update',
  ROLE_DETAIL: '/roles/detail',
  PERMISSIONS: '/permissions',
  NEWS: '/news',
  CREATE_NEWS: '/news/create',
  SEND_NOTIFICATIONS: '/send-notifications',
  NOTIFICATIONS: '/notifications',
  AUTH_LOGIN: '/auth/login',
  AUTH_FORGOTPASSWORD: '/auth/forgot-password',
  ENTER_OTP: '/auth/enter-otp',
  AUTH_CHAGEPASSWORD: '/auth/change-password',
  RESOURCE_BANK: '/resource-bank',
  DICTIONARY: '/dictionary',
  TOPICS: '/topics',
  TOPIC: '/topic',
  QUESTION: '/question',
  SETTING: '/setting',
  VIDEO: '/video',
  SYNC_HUBSPOT: '/sync-hubspot',
  TEACHERS: '/teachers',
  CREATE_TEACHERS: '/teachers/create',
  UPDATE_TEACHERS: '/teachers/update',
  AUTH_CHANGE_PASSWORD_SUCCESS: '/auth/password-success',
  IMPORT_LOG: '/import-history',
  COURSE_FULL: '/course-full',
  STORY: '/case-study',
  CREATE_STORY: '/case-study/create',
  STORY_DETAIL: '/case-study/detail',
  FOLDER_LIST: '/folder',
  //#region Classes
  CLASSES: '/classes',
  CLASSROOM_AREA: '/classroom/area',
  CLASSROOM_AREA_DETAIL: '/classroom/area/detail',
  CLASSROOM_AREA_CREATE: '/classroom/area/create',
  CLASSROOM_ROOM: '/classroom/room',
  CLASSROOM_ROOM_DETAIL: '/classroom/room/detail',
  CLASSROOM_ROOM_CREATE: '/classroom/room/create',
  CLASSROOM: '/classrooms',
  CLASSES_SHIFT: '/classes/shifts',
  CREATE_CLASSES: '/classes/create',
  CLASS_DETAIL: '/classes/detail',
  //#endregion
  PREVIEW_PART: '/preview-part',
  PREVIEW_UNIT: '/preview-unit',
  RESOURCE_MANAGER: '/resource-manager',
  CERTIFICATES: '/certificates',
  MAIL_LOGS: '/mail-logs',
  CREATE_TEMPLATE: '/create-template',
  LIST_GROUP: '/list-group',
  CREATE_GROUP: '/create-group',
  UPDATE_GROUP: '/update-group',
  UPDATE_CLASS: '/classes/update',
  LIST_BLOGS: '/blogs',
  SHOP_LIST_BLOGS: '/shop/blogs',
  NEW_POST: '/new-post',
  BLOG_CATEGORIES: '/blog/categories',
  TAGS: '/tags',
  AUTHORS: '/authors',
  SHOP_FAQS: '/shop/faqs',
  FAQ_DETAIL: '/shop/faqs/detail',
  FAQS_CATEGORY: '/faqs/category',
  SHOP_EVENT: '/shop/event',
  EVENT_CATEGORIES: '/shop/event/category',
  EVENT_TAGS: '/shop/event/tags',
  SHOP_NEW_EVENT: '/shop/new-event',
  PRODUCTS: '/products',
  PRODUCTS_EVENT: '/products/event',
  PRODUCT_DETAIL: '/product-detail',
  QUESTION_LIST: '/questions',
  GRADE: '/grade',
  LOG_PARTICIPANT: '/participant/report',
  PARTICIPANT_LOG_FILE: '/logs/TEST_PARTICIPANT',
  PARTICIPANTS: '/participants',
  LIST_SUPPORTER_GROUP: '/support-group',
  CREATE_SUPORTER_GROUP: '/support-group/create',
  CHECK_DUPLICATE_CONTACT: '/marketing/check-duplicate-contact',
  CHECK_DUPLICATE_CONTACT_RESULT: '/marketing/check-duplicate-contact/results',
  EVENT_TEST: '/event-test',
  CREATE_EVENT_TEST: '/create-test',
  EVENT_PARTICIPANT_LIST: '/event-test/participants-list',
  CACHE: '/caches',
  EXAMS: '/exams',

  //#region Departments
  DEPARTMENTS: '/departments',
  DEPARTMENT_CREATE: '/departments/create',
  DEPARTMENT_DETAIL: '/departments/:id/*',
  //#endregion
}
