import { Skeleton } from 'antd'
import SappTabs from 'src/components/tabs/SappTabs'
import { ITabs } from 'src/type'

interface IProps {
  tabs: ITabs[]
  loading: boolean
  title: string
}

const DetailHeader = ({ tabs, loading, title }: IProps) => {
  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='pt-10 px-10 pb-0'>
        <div className='d-flex align-items-center'>
          {/* Start Name */}
          {loading ? (
            <Skeleton.Button active block />
          ) : (
            <div className='text-gray-900 fs-2 fw-bold me-1'>{title}</div>
          )}
          {/* End Name */}
        </div>
        {/* Start Tabs */}
        {loading ? <Skeleton.Button active block /> : <SappTabs tabs={tabs} />}
        {/* End Tabs */}
      </div>
    </div>
  )
}

export default DetailHeader
